import React, { useState, useEffect } from 'react';
import LightView from './LightView'

import '../../css/electricalInteface.css'


export default function PlateVoltageView(props) {
  
	const [rotation, setRotation] = useState(0)
	const voltTunerRotation = props.voltTunerRotation
	const [rotationMeter, setRotationMeter] = useState(0)
 	const [direction, setDirection] = useState ("up")
 	
 	const rotate = () => {

 		if(direction === "down") {
 			let newRotation = rotation - 130
 			let newRotationMeter = rotationMeter - 55
 			//let newRotationMeter = rotationMeter - 11
 			if(newRotation < -130 ){
 				newRotation = rotation + 130
 				//newRotationMeter = rotationMeter + 11
 				newRotationMeter = rotationMeter + 55
 				setDirection("up")
	    	}
	    	setRotation(newRotation)
	    	setRotationMeter(newRotationMeter)

	    	if(newRotation === -130) {
	    		props.update(true)
	    	}else{
	    		props.update(false)
	    	}
	    	
 		}else {
 			let newRotation = rotation + 130
 			//let newRotationMeter = rotationMeter + 11
 			let newRotationMeter = rotationMeter + 55
 			if(newRotation > 130 ){
 				newRotation = rotation - 130
 				newRotationMeter = rotationMeter - 55
 				//newRotationMeter = rotationMeter - 11
 				setDirection("down")
	    	}
	    	setRotation(newRotation)
	    	setRotationMeter(newRotationMeter)

	    	if(newRotation === -130) {
	    		props.update(true)
	    	}else{
	    		props.update(false)
	    	}
 		}

 		
 		
 	}

 	let newRotationMeter = rotationMeter

 	if(voltTunerRotation === -120) {
 		newRotationMeter = newRotationMeter + 11
 	}else if(voltTunerRotation === -40) {
 		newRotationMeter = newRotationMeter + 2*11

 	}else if(voltTunerRotation === 40) {
 		newRotationMeter = newRotationMeter + 3*11
 	}else if(voltTunerRotation === 120) {
 		newRotationMeter = newRotationMeter + 4*11
 	}

 	if(newRotationMeter > 55){
 		newRotationMeter = 55;
 	}
 	if(newRotationMeter < -55){
 		newRotationMeter = -55;
 	}

	return (<div className="filament-container">

		<div className="light-container">
	   		<LightView isGreen={true} size="medium" isOn={true}/>
	   		<LightView isGreen={false} size="medium" isOn={false} />
   		</div>

		<div className="meter-container">
			<div className="meterPlateVoltage-base base">

				<img src="https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/dial.svg" className="dial"   style={{transform: `rotate(${newRotationMeter}deg)`}} />


			</div>

			<h2>Plate Voltage Meter</h2>
		</div>

		<div className="knob-container" >
		   	
		   	<div className="plateVoltage-base base">

		   		<img src="https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/knob.svg" className="knob" onClick={rotate} style={{transform: `rotate(${rotation}deg)`}} />

		   	</div>

		   	<h2>Plate Voltage</h2>
		</div>
	</div>
	);
}
