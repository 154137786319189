import React, { useState, useEffect } from 'react';
import LightView from './LightView'

import '../../css/electricalInteface.css'


export default function VolumeView(props) {
  
	const [rotation, setRotation] = useState(60)
 	const [direction, setDirection] = useState ("up")
 	
 	const rotate = () => {

 		if(direction === "down") {
 			let newRotation = rotation - 60
 			
 			if(newRotation < -120 ){
 				newRotation = rotation + 60
 				
 				setDirection("up")
	    	}
	    	setRotation(newRotation)

	    	if(newRotation === -120) {
	    		props.update(true)
	    	}else{
	    		props.update(false)
	    	}

 		}else {
 			let newRotation = rotation + 60
 			if(newRotation > 120 ){
 				newRotation = rotation - 60
 				setDirection("down")
	    	}
	    	setRotation(newRotation)

	    	if(newRotation === -120) {
	    		props.update(true)
	    	}else{
	    		props.update(false)
	    	}
 		}

 		
 		
 	}

	return (<div className="tuner-container">

		<div className="light-container">
	   		<LightView isGreen={true} size="medium" isOn={rotation != -120}/>
	   		<LightView isGreen={false} size="medium" isOn={rotation === -120} />
   		</div>

		

		<div className="knob-container" >
		   	
		   	<div className="volume-base tuner-base">

		   		<img src="https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/tuner-knob.svg" className="tuner-knob" onClick={rotate} style={{transform: `rotate(${rotation}deg)`}} />

		   	</div>

		   	<h2>Volume</h2>
		</div>
	</div>
	);
}
