import React from 'react'


export default function LockedVideoPasswordPopUp(props) {



	const tapForgotPw = () => {
		//console.log("tapForgotPw")
		props.setCurrentStep(1)
	    
	}

	

    return (<div className="h-full w-full flex justify-center items-center z-10 overlaypw" onClick={props.tapBack}>

	        	<div className="login-box rounded-lg flex flex-col justify-center items-center p-4 pt-10 pb-8">
	        		<div className="mr-2 "><img onClick={props.tapBack} className=" close-icon-modal close-folderlogin"  alt="close-dt" src="https://cdn.huntakiller.com/blair-witch/s1/close.svg"/></div>
	        	
		        	<h1  className=" text-lg">Locked Video</h1>

		        	<form className=" rounded  pt-6 " >
		        		<div className="">
		        			
              				<div className=" mb-3">
			        			<input className="shadow appearance-none border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="password" disabled={true} />
              					
              				</div>

              				<p className=" mb-3 text-center text-white forgot-password" onClick={tapForgotPw}>Forgot your password?</p>
              				<p className="error mt-2">&nbsp; </p>
		        		</div>
		        		<div className="flex items-center justify-between">
					      <button className="bg-grey mx-8  w-full text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" disabled={true}>
					        ENTER
					      </button>
					     
					    </div>
		        	</form>
		        	
		        </div>
	        </div>)
    
}
