import React, { Component } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import '../css/scrollbar.css'
export default class ImageViewer extends Component {
	constructor(props) {
        super(props);
        this.state = {
          initialWidthPercent:100,
          fetching:false,
          error:false,

        };
      }

	componentDidMount() {

  }
  componentWillUnmount() {
  }


  zoomOut = (e) => {
     let widthPercent = this.state.initialWidthPercent;
     widthPercent = Math.max(widthPercent - 20, 100); 
     this.setState({initialWidthPercent:widthPercent});
  };


  zoomIn = (e) => {
     let widthPercent = this.state.initialWidthPercent;
     widthPercent = Math.min(widthPercent + 20, 350); 
     this.setState({initialWidthPercent:widthPercent});
  };


   download = (url, name, e) => {
    e.preventDefault();
    if(!this.state.fetching){
        if (!url) {
          throw new Error("Resource URL not provided! You need to provide one");
        }

        this.setState({fetching:true});
        fetch(url+"?crossorigin")
          .then(response => response.blob())
          .then(blob => {
            this.setState({fetching:false});
            const blobURL = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = blobURL;
            a.style = "display: none";

            if (name && name.length) a.download = name.substring(3);
            document.body.appendChild(a);
            a.click();
          })
          .catch(() => this.setState({error:true}));
        }
    
  };



	render() {

    const contentFileStyle = {
      width:this.state.initialWidthPercent + "%"
    };

		return(
			<div className="imageViewer">
        <PerfectScrollbar>
          
          <div className="content-file" style={contentFileStyle}>
            <img src={"https://cdn.huntakiller.com/blair-witch/"+this.props.img_url} alt={this.props.img_name}/>
          </div>
        </PerfectScrollbar>
        <div className="bottom-imageViewer-controllers">
          <button onClick={this.zoomIn} disabled={this.state.initialWidthPercent>=300}>Zoom In</button>
          <button onClick={this.zoomOut} disabled={this.state.initialWidthPercent<=100}>Zoom Out</button>
          <a  href={"https://cdn.huntakiller.com/blair-witch/"+this.props.img_url}  onClick={(e)=> this.download("https://cdn.huntakiller.com/blair-witch/"+this.props.img_url, this.props.img_url, e)}  download={this.props.img_name} >{this.state.fetching === true ? "Loading ..." : "Download"}</a>

        </div>
			</div>)

	}
}