import React from 'react';


export default function HomeView() {
  

  
  return (
    <div className="homeSection">
    
        <img className="home-hero" src="https://cdn.huntakiller.com/blair-witch/s2/bwp0201-photo-of-kent-house.jpg" alt="kent house" />

        <div className="home-divider"></div>

        <h2>SuperNeutral's First Field Expedition:</h2>
        <h1>The Blair Witch!</h1>
        
        <p><br/>Thank you all so much for supporting our show thus far. We are excited and proud to present our newest adventure in partnership with Castwire. It is thanks to their financial support and your voracious appetites for the unknown that we have been able to plan a full investigation into the Blair Witch on site in Burkittsville, Maryland!<br/><br/>Stay tuned for updates! Oh, what, you want more right now? Check out the pinned post in the forums for more information on this new expansion of SuperNeutral or listen to our original minisode on the Burkittsville mystery <a href="https://www.superneutralpod.com/" target="_blank"  rel="noopener noreferrer">here.</a></p>
    

        <div className="home-divider"></div>

        <h2>Our Historical Timeline of Burkittsville and the Blair Witch</h2>

        <div className="homescrollable-container">

            <div className="homescrollable-content">

                <div className="flex">
                    <p className="homescrollable-copy-header red">February 1785</p>
                    <p>A local woman is accused of and tried for witchcraft. She is found guilty and sentenced to death by exposure. At this time, the township is still known as Blair.</p>
                </div>


                <div className="flex">
                    <p className="homescrollable-copy-header blue">November 1786</p>
                    <p>All of the woman's accusers and half of Blair's children vanish. The mere mention of the name of the witch is deemed heretical, evil; and Blair itself is abandoned, for fear of the witch's curse.</p>
                </div>


                <div className="flex">
                    <p className="homescrollable-copy-header red">1824</p>
                    <p>Burkittsville is founded on the land that used to be Blair.</p>
                </div>

                <div className="flex">
                    <p className="homescrollable-copy-header blue">August 1825</p>
                    <p>A young girl drowns in one of the creeks near Burkittsville. Her body is never recovered, though eleven witnesses report she was “grabbed” by something in the water.</p>
                </div>

                <div className="flex">
                    <p className="homescrollable-copy-header red">March 1886</p>
                    <p>Search parties are dispatched for a missing child. Though the child is recovered unharmed, one of the search parties is found weeks later, mutilated.</p>
                </div>

                <div className="flex">
                    <p className="homescrollable-copy-header blue">November 1940 – May 1941</p>
                    <p>Several children are mysteriously abducted.</p>
                </div>

                <div className="flex">
                    <p className="homescrollable-copy-header red">May 25, 1941</p>
                    <p>A local man turns himself in, confessing to the abduction and murders of all seven children. Evidence related to all cases is found at his home in the woods.</p>
                </div>

                <div className="flex">
                    <p className="homescrollable-copy-header blue">October 1994</p>
                    <p>Three student filmmakers go missing while reportedly hiking through the woods.</p>
                </div>

                <div className="flex">
                    <p className="homescrollable-copy-header red">1996</p>
                    <p>Nine-year-old Peter Shannon goes missing, as do members of the team sent to search for him. Rumors of unknown persons living in the woods and stealing children spread through the town.</p>
                </div>

                <div className="flex">
                    <p className="homescrollable-copy-header blue">Fall of 1999</p>
                    <p>A group of young adults are reported missing after venturing to Burkittsville for unknown reasons.</p>
                </div>

                <div className="flex">
                    <p className="homescrollable-copy-header red">Summer of 2014</p>
                    <p>[Investigation Ongoing]</p>
                </div>

                <div className="flex">
                    <p className="homescrollable-copy-header blue">Most recent</p>
                    <p>Members of the Kent family (Liam, Tristan, and Rosemary) all disappear. Investigation Ongoing.</p>
                </div>

  


            </div>

        </div>

    </div> 
  );
}