import React, { useState } from 'react';


const PASTINVESTIGATIONS = {
  BIGFOOT: {
    name: "Bigfoot",
    description: 'Everybody knows this fella. From Tibet to America, practically every culture has a story of one giant ape-man or another, and while there are plenty of admitted hoaxes out there, there\'s also a ton of evidence to suggest he is, or at least was, a reality.',
  	ed:"80",
  	james:"80",
  	img:"https://cdn.huntakiller.com/blair-witch/s2/bwp0201-bigfoot-full-desktop.jpg",
  	editornote:'(Editor\'s Note: I\'m only conceding this number because of actual evidence of Gigantopithecus. So, yeah, maybe it was real at one time, but probably not now. —James)'

  },
  JERSEYDEVIL: {
    name:'Jersey Devil',
    description:'Birth abnormality or spawn of the devil? Either way, something strange has been happening in New Jersey\'s Pine Barrens. Reports of Mother Leeds\'s infamous 13th child date all the way back to before the first big sighting boom in 1909. There\'s even a story of Stephen Decatur Jr. firing a cannonball at it, to no effect. Although there\'s plenty of evidence that proves the Leeds family actually existed, what happened to the Devil of Leeds is a little foggier.',
    ed:'70',
    james:'30',
    img:"https://cdn.huntakiller.com/blair-witch/s2/bwp0201-jersey-devil-full-desktop.jpg"
  },
  GOATMAN: {
    name:'Goatman',
    description:'The Maryland-born version of a bipedal goat beast has also been sighted in Texas and Kentucky where he\'s known, respectively, as the Pope Lick Monster and the Lake Worth Monster. Is he a circus performer who swore revenge after his mistreatment? A man who was experimented on, gaining goat-like features? Or a reincarnated farmer who in life sacrificed goats to Satan? One thing\'s for sure, you wouldn\'t want to be caught when the sun\'s down on an abandoned stretch of road anywhere he\'s around.',
    ed:'60',
    james:'10',
    img:"https://cdn.huntakiller.com/blair-witch/s2/bwp0201-goatman-full-desktop.jpg"
  },
  BLACKSHUCK: {
    name:'Black Shuck',
    description:'Sightings of sinister, spectral black dogs (though not always named Shuck) date back as far as the 1500s in England. Occasionally the dogs are seen with a single, cyclopean eye, but they are always glowing red. Shuck and other such dogs are portents of doom, and that\'s never been more clear than in his most infamous appearance: a day of fatal attacks at both Suffolk\'s Holy Trinity and St. Mary\'s Churches. Whether or not the dogs are hounds from hell, it isn\'t too hard to imagine large dogs wreaking havoc and disappearing in the vast English countryside.',
    ed:'90',
    james:'65',
    img:"https://cdn.huntakiller.com/blair-witch/s2/bwp0201-black-shuck-full-desktop.jpg"
  },
  SEWERALLIGATORS: {
    name:'Sewer Alligators',
    description:'Puppies are a super popular pet for Christmas. But what if you decided to get your family something a little larger? And that big guy became a little... hungrier? Such is the origin of sewer alligators, sightings of which go as far back as the 1920s. While many large reptiles have been confirmed in New York\'s sewers, they have all been illegal pets that have been released. The temperature and bacteria of any sewer system makes it impossible for reptiles to survive for a long period of time.',
    ed:'100',
    james:'100',
    img:"https://cdn.huntakiller.com/blair-witch/s2/bwp0201-sewer-alligators-full-desktop.jpg"
  },
  VILEVORTICES: {
    name:'Vile Vortices',
    description:'There are some places on Earth where disappearances and reported phenomena skyrocket, like the Bennington, Bridgewater, or Bermuda Triangles, or Burkittsville, MD (Editor\'s note: I promise they don\'t all start with B. —Ed). What could the culprit be? Ley lines (Editor\'s Note: No. —James)? Psychic energy (Editor\'s Note: Definitely not. —James)? Curses (Editor\'s Note: How many times... —James)? Ghosts (Editor\'s Note: Okay, maybe. —James)? With areas that are too big for a simple haunting, there\'s definitely something going on in these cursed little pockets of the world.',
    ed:'85',
    james:'40',
    img:"https://cdn.huntakiller.com/blair-witch/s2/bwp0201-vile-vortices-full-desktop.jpg"
  }
};



export default function PastInvestigationsView(props) {
  
	const [currentView, setCurrentView] = useState("")


	const setCurrentInvestigation = (name) => {

		window.scrollTo({
	        behavior: "auto",
	        top:  document.getElementById("blogcontent").offsetTop - document.getElementById("top-bar-menu").offsetHeight
	      });

		setCurrentView(name)
	}

 	

  
	return (

		<div id="past-investigation-container">
		{(currentView === "") ? (

          	<div className="past-investigations-section ">
					<div className="flex">

			  		<div onClick={function(){setCurrentInvestigation("BIGFOOT")}}>
			  			<img src="https://cdn.huntakiller.com/blair-witch/s2/bwp0201-bigfoot-full-desktop.jpg" alt = "bigfoot"/>
			  			<h1>Bigfoot</h1>
			  		</div>
			  		<div onClick={function(){setCurrentInvestigation("JERSEYDEVIL")}}>
			  			<img src="https://cdn.huntakiller.com/blair-witch/s2/bwp0201-jersey-devil-full-desktop.jpg"  alt ="Jersey Devil"/>
			  			<h1>Jersey Devil</h1>
			  		</div>
			  		
			  	</div>

			  	<div className="flex">

			  		
			  		<div onClick={function(){setCurrentInvestigation("GOATMAN")}}>
			  			<img src="https://cdn.huntakiller.com/blair-witch/s2/bwp0201-goatman-full-desktop.jpg"  alt ="Goatman"/>
			  			<h1>Goatman</h1>
			  		</div>
			  		<div onClick={function(){setCurrentInvestigation("BLACKSHUCK")}}>
			  			<img src="https://cdn.huntakiller.com/blair-witch/s2/bwp0201-black-shuck-full-desktop.jpg" alt ="Black Shuck"/>
			  			<h1>Black Shuck</h1>
			  		</div>
			  		
			  	</div>

			  	<div className="flex">

			  		
			  		<div onClick={function(){setCurrentInvestigation("SEWERALLIGATORS")}}>
			  			<img src="https://cdn.huntakiller.com/blair-witch/s2/bwp0201-sewer-alligators-full-desktop.jpg" alt ="Sewer Alligators" />
			  			<h1>Sewer Alligators</h1>
			  		</div>
			  		<div onClick={function(){setCurrentInvestigation("VILEVORTICES")}}>
			  			<img src="https://cdn.huntakiller.com/blair-witch/s2/bwp0201-vile-vortices-full-desktop.jpg"  alt ="Vile Vortices"/>
			  			<h1>Vile Vortices</h1>
			  		</div>
			  	</div>
			</div>



        ) : (

        	<div className="past-investigation-section" >
        		<img src="https://cdn.huntakiller.com/blair-witch/s2/close-button-white.svg" className="past-investigation-close-button" alt="close buttonn" onClick={function(){setCurrentView("")}}/>
        		<img src={PASTINVESTIGATIONS[currentView].img} onClick={function() {props.openLighbox(PASTINVESTIGATIONS[currentView].img)}} alt={PASTINVESTIGATIONS[currentView].name}/>
        		<h1>{PASTINVESTIGATIONS[currentView].name}</h1>
        		<p>{PASTINVESTIGATIONS[currentView].description}</p>
        		<h2>Probability that it's real</h2>
        		<h3>Ed: {PASTINVESTIGATIONS[currentView].ed}%</h3>
        		<h3 className="blue">James: {PASTINVESTIGATIONS[currentView].james}%</h3>

        		{(PASTINVESTIGATIONS[currentView].editornote) && (
        			<p className="editornote">{PASTINVESTIGATIONS[currentView].editornote}</p>
        		)}
        		
        	</div>


        )}


        </div>

		 
	);
}