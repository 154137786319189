import React from 'react';
import ReactDOM from 'react-dom';
import LoginEpisodeView from './views/LoginEpisodeView';
import EpisodeView from './views/EpisodeView';
//import Inbox from './views/InboxMOM'


import '../node_modules/tailwindcss/dist/tailwind.min.css'
import './css/index.css'
import 'react-image-lightbox/style.css'; 

import { Route, BrowserRouter  as Router, Switch } from 'react-router-dom'



const routing = (
	<Router>
	  <Switch>
	      <Route exact path="/" component={LoginEpisodeView} />
	      <Route exact path="/Desktop" component={EpisodeView} />
	     
	  </Switch>
  	</Router>
)

ReactDOM.render(routing, document.getElementById('root'))
