import React, { useState, useEffect } from 'react';
import store from 'store';
import { useHistory } from 'react-router-dom';
import '../css/dock.css'



export default function Dock(props) {
  
	const history = useHistory();
	const [time, setTime] = useState(new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}));
	const [logout] = useState(props.logout);

	

  useEffect(() => { 
    //console.log("useEffect Dock")

    let interval = null;
    interval = setInterval(() => {
        setTime(new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}));
      }, 1000);

    return () => clearInterval(interval);
  }, [time])




  const handleLogout = (event) => {

    event.preventDefault();
    store.remove('passwordBW2')
    history.push("/")

  }

  

  return (
   <div className="dock-container">
	    <div>{logout === "true" && <a href="/"  onClick={handleLogout} >Log Out</a>}</div>
		<p className="App-clock">{time}</p>
				
	</div>
  );
}
