import React from 'react'
import ReactPlayer from 'react-player'

export default function VideoViewer(props) {


	const onEndedVideo = (e) => {
		console.log("onEndedVideo")
		props.setTimerLogout()
	}
	

	

    return (<div className="videoViewer">
    	<ReactPlayer url={props.video_url} controls={true} playing={true} onEnded={onEndedVideo}/>
    	</div>)
    
}
