import React from 'react';


export default function MessageBoardEpisode3(props) {
  

  
  return (<>
    <div className="blog_entry">
    	<div className="blog_title">
    		<h2>Weird photo</h2>
    		<h3>@yallstayspooky</h3>
    	</div>

    	<div className="blog_text">
    		<p>Spooky fam, this photo is creeping me out. I took it at my cousin's wedding, and there appears to be a shadowy figure standing next to her. I don't know why, but I really think it might be my grandpa. He died six months ago, and he and my cousin were very close. If you zoom in, you can even see the bowtie he used to wear. Let me know what you think.<br/><br/>
            <img src="https://cdn.huntakiller.com/blair-witch/s2/bwp0203-wedding-photo-desktop.jpg"  alt="wedding"  onClick={() => props.openLighbox("https://cdn.huntakiller.com/blair-witch/s2/bwp0203-wedding-photo-desktop.jpg")} /></p>
    	</div>

    	<div className="thread-reply-container">
			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@banshe72216</p>
					<p className="reply-content">Not to rain on your parade OP but that is literally your finger covering the lens.</p>
				</div>		
			</div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@myBFFLisaghost</p>
                    <p className="reply-content">Agreed.</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@thehauntedmaus</p>
                    <p className="reply-content"><span role="img" aria-label="pointing-index-emoji">☝️</span></p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@yallstayspooky</p>
                    <p className="reply-content">Well this is embarrassing.</p>
                </div>      
            </div>
    	</div>
	</div>

    <div className="blog_entry">
        <div className="blog_title">
            <h2>Need some more decoding help</h2>
            <h3>@mandyfromknoxville</h3>
        </div>

        <div className="blog_text">
            <p>Me again! So this message from my son is DEFINITELY in code. I'm 100% sure this time!!! He sent it when I texted him to ask if he had read my latest email.<br/><br/>TBH, TL;DR<br/><br/>Could it be a keyword cipher, maybe? I read they work like this:<br/><br/>KEYWORDABCFGHIJLMNPQSTUVXZ<br/>ABCDEFGHIJKLMNOPQRSTUVWXYZ<br/><br/>Basically, you write out a regular alphabet. Then, you write a new alphabet on top of it where the keyword is first, followed by the rest of the letters (doesn't have to be the word “keyword” obviously, it can be anything you want!). You should make sure that no letter repeats itself on the upper line. I'm pretty sure that's right.<br/><br/>I'm not sure how to decode, though. Do you go down or up?</p>
        </div>

        <div className="thread-reply-container">
            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@the_great_abracadabulous</p>
                    <p className="reply-content">With the configuration you have, you should be decoding downward. K becomes A, and so forth.<br/><br/>However, I don't think that's a cipher.</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@dayneofthedead</p>
                    <p className="reply-content">Why. Are. We. Doing. This. Again. And why are you mincing words, @the_great_abracadabulous? Mandy, you have shown your ignorance of internet acronyms once more.<br/><br/>"To be honest, too long; didn't read."<br/><br/>Which is how I wish I had treated this post.</p>
                </div>    

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@aliceinwonder24</p>
                        <p className="reply-content">No one asked for your opinion, Dayne.</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@dayneofthedead</p>
                        <p className="reply-content">Um she LITERALLY DID.</p>
                    </div>      
                </div>  
            </div>




        </div>
    </div>

    <div className="blog_entry">
        <div className="blog_title">
            <h2>Evil twin?</h2>
            <h3>@prodigiousmonipogo42</h3>
        </div>

        <div className="blog_text">
            <p>Do any of you fine folks believe in doppelgangers? I took the bus to work the other day, and there was somebody who looked JUST like me a few seats ahead. I didn't get a super good look at their face tho. Could have just been a coincidence, but it freaked me out. There's precedence for that, right?</p>
        </div>

        <div className="thread-reply-container">
            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@iamthechupatrooper</p>
                    <p className="reply-content">You're correct that some people believe a doppelganger is an omen of death. However, others just think it means bad luck in general. Or that it represents a parallel universe. No matter what, I wouldn't worry about it. Like you said, you never really saw their face. Maybe they don't look as much like you as you thought.</p>
                </div>     

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@princessofdarqueness</p>
                        <p className="reply-content">Also I think the legend is that you have to see it three times or something, right?</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@aliceinwonder24</p>
                        <p className="reply-content">I don't know about that specifically, but I think the general consensus is that the more you see it, the worse the omen.</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@prodigiousmonipogo42</p>
                        <p className="reply-content">Guess who was at the grocery story today.</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@iamthechupatrooper</p>
                        <p className="reply-content">I'm sure it's fine.</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@prodigiousmonipogo42</p>
                        <p className="reply-content">No offense, but you wouldn't be so chill if this were happening to you.</p>
                    </div>      
                </div> 
            </div>


        </div>
    </div>

    <div className="blog_entry">
        <div className="blog_title">
            <h2>Unwelcome Nighttime Visitor</h2>
            <h3>@idobelieveingene</h3>
        </div>

        <div className="blog_text">
            <p>Has anyone here ever had sleep paralysis? I've been experiencing some really weird stuff at night lately.</p>
        </div>

        <div className="thread-reply-container">
            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@princessofdarqueness</p>
                    <p className="reply-content">What kind of weird stuff?</p>
                </div> 

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@idobelieveingene</p>
                        <p className="reply-content">I wake up and I can't breathe. Like there's somebody choking me. The first few times my wife didn't even notice. She just laid there.</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@princessofdarqueness</p>
                        <p className="reply-content">They call that the "night hag." People used to think it was an old lady spirit causing all the trouble.</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@idobelieveingene</p>
                        <p className="reply-content">Mine is an old man.</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@princessofdarqueness</p>
                        <p className="reply-content">As in you literally see a person?</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@idobelieveingene</p>
                        <p className="reply-content">Yes. The other night he was hovering right over my wife. She didn't believe me when I said I saw him.</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@aliceinwonder24</p>
                        <p className="reply-content">I'm sure it's just part of the phenomenon. Kind of like a dream.</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@idobelieveingene</p>
                        <p className="reply-content">Then why do I have marks on my neck the next day?</p>
                    </div>      
                </div>     
            </div>


        </div>
    </div>

    <div className="blog_entry">
        <div className="blog_title">
            <h2>Listen to this</h2>
            <h3>@everydarndayishalloween</h3>
        </div>

        <div className="blog_text">
            <p>Want to hear something creepy? I played part of a recent SuperNeutral episode backwards and here's what I got.</p>
            <audio controls>
              <source src="https://cdn.huntakiller.com/blair-witch/s2/audio/creepy-X345445.mp3" type="audio/mpeg" />
            
            </audio>
        </div>

        <div className="thread-reply-container">
            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@gothandpescatarian</p>
                    <p className="reply-content">Thanks for the nightmares.</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@princessofdarqueness</p>
                    <p className="reply-content">That's disturbing.</p>
                </div> 

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@idobelieveingene</p>
                        <p className="reply-content">Are Ed and James ok?</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@dayneofthedead</p>
                        <p className="reply-content">I'm sure they're fine. They're just out in the middle of nowhere. Let's not get hysterical.</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@je_suis_le_rougarou</p>
                        <p className="reply-content">Following</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@bewarethelariosauro</p>
                        <p className="reply-content">same</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@aliceinwonder24</p>
                        <p className="reply-content">Seriously tho, has anybody heard anything from them?</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@aliceinwonder24</p>
                        <p className="reply-content">Anybody????</p>
                    </div>      
                </div>     
            </div>

        </div>
    </div>
    </> 
  );
}