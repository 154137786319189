import React, { useState, useEffect } from 'react';
import LightView from './LightView'

import '../../css/electricalInteface.css'


export default function PAVoltTuner(props) {
  
	const [rotation, setRotation] = useState(40)
 	const [direction, setDirection] = useState ("up")
 	
 	const rotate = () => {

 		if(direction === "down") {
 			let newRotation = rotation - 80
 			
 			if(newRotation < -120 ){
 				newRotation = rotation + 80
 				
 				setDirection("up")
	    	}
	    	setRotation(newRotation)
	    	props.updateVoltTunerRotation(newRotation)
	    	if(newRotation === -40) {
	    		props.update(true)
	    	}else{
	    		props.update(false)
	    	}

 		}else {
 			let newRotation = rotation + 80
 			if(newRotation > 120 ){
 				newRotation = rotation - 80
 				setDirection("down")
	    	}
	    	setRotation(newRotation)
	    	props.updateVoltTunerRotation(newRotation)
	    	if(newRotation === -40) {
	    		props.update(true)
	    	}else{
	    		props.update(false)
	    	}

 		}

 		
 		
 	}

	return (


		<div className="bottomPart">
			<div className="tuner-container">

			

			

				<div className="knob-container" >
				   	
				   	<div className="paVolt-base tuner-base">

				   		<img src="https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/tuner-knob.svg" className="tuner-knob" onClick={rotate} style={{transform: `rotate(${rotation}deg)`}} />

				   	</div>

				   	<h2>P.A. Volt. Tuner</h2>
				</div>

				<div className="light-container">
			   		<LightView isGreen={true} size="medium" isOn={true}/>
	   				<LightView isGreen={false} size="medium" isOn={false} />
		   		</div>


			</div>

			<img src="https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/radio-tower-logo.svg" className="logo-tower" />
	</div>
	);
}
