import React from 'react';


export default function MessageBoardEpisode4(props) {
  

  
  return (<>
    <div className="blog_entry">
    	<div className="blog_title">
    		<h2>Insider Info: SuperNeutral Production Halted</h2>
    		<h3>@hearseandcasket</h3>
    	</div>

    	<div className="blog_text">
    		<p>Bad news folx. My roommate's sister is an intern at Castwire, and she just told me that they're canning Ed and James' SuperNeutral. This means that the Burkittsville special and other episodes are on hold. She says Castwire still wants to do something with those new hosts, but Ed and James are pretty much out. She said they were trying to keep this news under wraps, but I thought everyone here needed to know.</p>
    	</div>

    	<div className="thread-reply-container">
			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@dayneofthedead</p>
					<p className="reply-content">Castwire can suck it!</p>
				</div>		
			</div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@aliceinwonder24</p>
                    <p className="reply-content">For once we agree, Dayne.</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@nessietonosferatu</p>
                    <p className="reply-content">Is this true @SNmoderator_nightofthelivinged and @SNmoderator_jamesalope????</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@gothandpescatarian</p>
                    <p className="reply-content">Yes, please let us know if this is real.</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@iamthechupatrooper</p>
                    <p className="reply-content">I think they're still in Burkittsville. Doubt we'll get a response anytime soon.</p>
                </div>      
            </div>


    	</div>
	</div>

    <div className="blog_entry">
        <div className="blog_title">
            <h2>Creepy game</h2>
            <h3>@taraandkaydensmom</h3>
        </div>

        <div className="blog_text">
            <p>This might seem a little superstitious, but better safe than sorry. My daughter just received this game called Legends of the Grimoire as a birthday gift. Has anybody on here played it? Anyway, the name Grimoire sounded pretty creepy, so I did an internet search on it, and what I found was really disturbing. The most worrying thing I read was about this one card that has this creepy figure with a night sky. They say kids have jumped off buildings and things like that after seeing it. Do you think any of that is true?</p>
        </div>

        <div className="thread-reply-container">
            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@aliceinwonder24</p>
                    <p className="reply-content">No need to worry. It's just a game. Don't believe everything you read on the internet.</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@tillproven87</p>
                    <p className="reply-content">Legends is my favorite game, and I've not been haunted yet (fingers crossed). It's actually based on a lot of real folklore. Very fun but also very well-researched. Your daughter will love it!</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@gothandpescatarian</p>
                    <p className="reply-content">Ahhhh Legends of the Grimoire! Best game EVER!</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@taraandkaydensmom</p>
                    <p className="reply-content">Thanks for all your replies. I appreciate it, but... At the risk of sounding paranoid, something really weird just happened. I know everybody said it was safe, but I just didn't want to take the risk, so I took the card and threw it in the trash. Silly, I know. Only thing is... I saw her playing yesterday morning, and the card was back in the deck.</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@iamthechupatrooper</p>
                    <p className="reply-content">I'm sure she just fished it out of the garbage. Kids!</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@taraandkaydensmom</p>
                    <p className="reply-content">I don't think she could. I put it in the outside bin right before they picked it up.</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@yallstayspooky</p>
                    <p className="reply-content">My cousin's best friend had that game, and she drowned in a pool a few weeks after she started playing it. She was on the swim team. OP, I don't know you or your kid, but I would burn the whole deck if I were you.</p>
                </div>      
            </div>


        </div>
    </div>

    <div className="blog_entry">
        <div className="blog_title">
            <h2>Evil or just mad?</h2>
            <h3>@myBFFLisaghost</h3>
        </div>

        <div className="blog_text">
            <p>Help?? The ghost at the train station I was talking to has turned evil! I went down there to listen in and continue our conversation like we have been the past few nights, but this time, before I could even turn on the radio, I heard a loud screeching noise and then several bangs. I waited for a second to see if maybe it was just the pipes or something, but then some metal shot out from the darkness and almost decapitated me! I'm still shaking from the experience...<br/><br/>Did I make it mad? How can I make it feel better?</p>
        </div>

        <div className="thread-reply-container">
            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@banshee_and_the_wailers</p>
                    <p className="reply-content">Maybe there's more than one ghost?</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@aliceinwonder24</p>
                    <p className="reply-content">I hear ghosts like pie and rocky road ice cream, but let's be real here, who doesn't like rocky road ice cream?</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@burkittsvillain</p>
                    <p className="reply-content">You shouldn't have trusted the “ghost” in the first place. Whatever it was didn't and doesn't have your best interests at heart.</p>
                </div>      
            </div>


        </div>
    </div>

    <div className="blog_entry">
        <div className="blog_title">
            <h2>Out of curiosity...</h2>
            <h3>@bewarethelariosauro</h3>
        </div>

        <div className="blog_text">
            <p>Have any of you ever had a paranormal experience in a language other than your own? I always see posts about weird messages in English, ciphers, codes, and whatnot, but I've been getting interested in linguistics, xenolalia, and stuff like that. So I'm super curious to hear if you have any stories of experiencing different, new, old, w/e languages in a paranormal setting.</p>
        </div>

        <div className="thread-reply-container">
            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@tillproven87</p>
                    <p className="reply-content">what's xenalalia?</p>
                </div>   

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@aliceinwonder24</p>
                        <p className="reply-content">Xenolalia. It's like when people speak or understand languages they don't normally</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@iamthechupatrooper</p>
                        <p className="reply-content">Is that like speaking in tongues? If so, I had an aunt perform a ritual over me after she found my ouijii board when I was 14.</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@dayneofthedead</p>
                        <p className="reply-content">It's spelled ouija. O-U-I-J-A</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@aliceinwonder24</p>
                        <p className="reply-content">Oh please.</p>
                    </div>      
                </div>   
            </div>


            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@princessofdarqueness</p>
                    <p className="reply-content">After I had my first son, we went to Spain to spend some time with my then-fiance and his family. While there, my fiance decided it was a great idea to take me geocaching for the first time. We ended up getting lost near this old warehouse (I think, I still don't really know what it was). I felt this chill run down my spine and then I heard a soft but extremely creepy voice say "peligro." Now i don't speak any Spanish, but for some reason I knew it meant we had to leave. I instantly grabbed my fiance and we booked it. The next day we learned that there was a gas leak there. Needless to say we've never gone geocaching again.</p>
                </div>    

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@idobelieveingene</p>
                        <p className="reply-content">That's so sad that it ruined your geocaching adventures though! I'd love to go around Spain someday.</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@mandyfromknoxville</p>
                        <p className="reply-content">We're going to Barcelona this summer! Can't wait! Although I sure hope we don't meet any ghosts!!!! Hope everybody is doing great. LOL to you all (lots of love)!!!</p>
                    </div>      
                </div>  
            </div>



        </div>
    </div>
    </> 
  );
}