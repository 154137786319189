import React, { Component } from 'react'
import Div100vh from 'react-div-100vh'
import 'react-perfect-scrollbar/dist/css/styles.css';

import store from 'store';

import EnterEmailView from '../components/Inbox/EnterEmailView'

import { Player } from '../models/player';

import EmailClient from '../components/EmailClient/email-client';

import { sendKlaviyoEvent } from '../utils/klaviyo.js';


export default class Inbox extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props.currentEpisode)
        this.currentSeasonIndex = this.props.currentSeason
        this.currentEpisodeIndex = this.props.currentEpisode
        this.subject = ""
        
        const userEmail = store.get("userEmailBW");

        fetch('./config/config_1.json').then(response => {
          return response.json();
        }).then(data => {
          // Work with JSON data here
          //console.log(data.botConfiguration.stepsPerEpisode);
          this.setState({ stepsPerEpisode: data.botConfiguration.stepsPerEpisode})

        }).catch(err => {
          // Do something for an error here
        });

        this.state = {
            showEmailClient: true,
            userEmail:userEmail,
            stepsPerEpisode:[],
            currentEpisodeIndex:this.props.currentEpisode
        };

    }

    closeModal = (e) => {
        if (typeof document.getElementsByTagName("audio")[0] !== 'undefined') {
            document.getElementsByTagName("audio")[0].pause();
        }
        this.props.tapBack()
    };


    showEmailSentToast = () => {
        this.props.showInboxToast()
        
    }


    closeEmailPopUp = (email) => {
        this.setState({ userEmail:email})
    }

    handleBotEmailResponse = (botResponse) => {
        const correctQuestion = botResponse.indexOf('{{CorrectQuestion}}');
        if (correctQuestion !== -1) {
            // Call API
            console.log('Correct');
            //console.log(this.state.stepsPerEpisode)

            if(this.state.stepsPerEpisode[Math.floor(this.state.currentEpisodeIndex)] > 1) {
                var maxStep = Math.floor(this.state.currentEpisodeIndex)+this.state.stepsPerEpisode[Math.floor(this.state.currentEpisodeIndex)]/10
                

                //console.log(maxStep)
                if(this.state.currentEpisodeIndex < maxStep) {
                    
                    //sendKlaviyoEvent(this.state.currentEpisodeIndex,this.state.userEmail);
                    
                    let newStep = this.state.currentEpisodeIndex + (Number.isInteger(this.state.currentEpisodeIndex) ? 0.2 : 0.1);
                    this.setState({ currentEpisodeIndex:newStep})
                    this.refs.emailClient.updateGameContext(newStep);

                    this.props.updateEpisode(newStep)

                }else {
                    sendKlaviyoEvent(this.currentEpisodeIndex,this.state.userEmail);

                    var event = new CustomEvent('CHARACTERRESPONSE', {detail :  { "season":"bw0"+this.currentSeasonIndex, "episode":"0"+this.currentEpisodeIndex, "success": "bw0"+this.currentSeasonIndex+"0"+this.currentEpisodeIndex} });
                    window.dispatchEvent(event);
                }
            }else {
                sendKlaviyoEvent(this.currentEpisodeIndex,this.state.userEmail);

                var event = new CustomEvent('CHARACTERRESPONSE', {detail :  { "season":"bw0"+this.currentSeasonIndex, "episode":"0"+this.currentEpisodeIndex, "success": "bw0"+this.currentSeasonIndex+"0"+this.currentEpisodeIndex} });
                window.dispatchEvent(event);
            }
            
            /*var currentEpisode = Math.floor(this.state.currentEpisodeIndex);*/

            /*
            */

        

        } else {
            console.log('Incorrect');
        }
    }

    triggerEmail = (message) => {
        this.refs.emailClient.loadBotAnswer(message)
    }



    render() {
        // Render appointment list if active.
        // playerName and playerAvatar values are need to invoke client.

        const renderEmailClient = () => {
            
            if (this.state.showEmailClient) {
                const player = new Player();
                player.email = this.state.userEmail;
                player.name = '';
                player.avatar = '';
                const initialEmailSubject = this.subject;
                const configurationLocation = '/config/config_1.json';
                //console.log(configurationLocation)
                return <EmailClient player      = {player}
                    initialEmailSubject         = {initialEmailSubject}
                    configurationLocation       = {configurationLocation}
                    botEmailResponse            = {this.handleBotEmailResponse}
                    showToast                   = {this.showEmailSentToast}
                    currentEpisode              = {this.currentEpisodeIndex}
                    ref                         = "emailClient"
                />;
            }
            return null;
        };



        return (
          <Div100vh  dontresize="true" className="h-full w-full flex flex-col inbox">
                <div className="modalcontainer inboxcontainer w-full h-full">
                    <div className="header">
                        <h2>Contact</h2>< button className="close" onClick={this.closeModal}/>  
                    </div>
                    <div className="  w-full h-full HAKEmailChatContainer">
                     {this.state.userEmail  !== undefined && (renderEmailClient())}
                     {this.state.userEmail  === undefined && (<EnterEmailView closeCallback={this.closeEmailPopUp.bind(this)}/>)}
                    </div>

                 </div>
            </Div100vh>
        )
    }
}

