import React, { useState, useEffect, useRef } from 'react';
import Div100vh from 'react-div-100vh';
import store from 'store';
import { useHistory } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import GlitchFx from 'react-glitch-fx/lib/GlitchFx';

import Inbox from './InboxMOM'
import HomeView from './HomeView'
import AboutView from './AboutView'
import PastInvestigationsView from './PastInvestigationsView'
import Dock from '../components/Dock'
import Modal from '../components/Modal'
import DesktopIcon from '../components/DesktopIcon'
import MobileMenu from '../components/MobileMenu'
import LockedVideo from  '../components/LockedVideo/LockedVideo'
import BlogPostFromReconTeam from  '../components/season1/BlogPostFromReconTeam'
import MessageBoard from '../components/MessageBoard/MessageBoard'
import ElectricalInterfaceView from '../components/ElectricalInterface/ElectricalInterfaceView'

import { Toast } from 'react-bootstrap';

// HAK styles
import '../styles/app.scss';
import '../components/EmailClient/email-client.scss';

import PASSWORDS  from '../datas/passwords.js';
import ICONS  from '../datas/icons.js';
import {getGetChildrenFromFolder, getIconFromType } from '../utils/utils.js';
export default function EpisodeView() {
  
  const inbox = useRef(null); 
  const history = useHistory();
  const [currentView, setCurrentView] = useState("home")
  const [password, setPassword] = useState("");
  const [showBlogPostFromReconTeam, setShowBlogPostFromReconTeam] = useState(false)
  const [showInbox, setShowInbox] = useState(false)
  const [showToastInbox, setShowToastInbox] = useState(false)
  const [showElectricalInterface, setShowElectricalInterface] = useState(false)
  const [icons, setIcons] = useState([])
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [currentBlogEntry, setCurrentBlogEntry] = useState(0)

  const [modals, setModals] = useState([])
  const [isHacked, setIsHacked] = useState(false)

  const [lightBoxImage, setLightBoxImage] = useState(null)
  const [showLockedVideo, setShowLockedVideo] = useState(false)
  const [currentEpisode, setCurrentEpisode] = useState(0)
  useEffect(() => { 
    //console.log("useEffect")
    //console.log("useEffect EpisodeView")
    window.location.hash = ""
    const passwordStorage = store.get("passwordBW2")
    
    
    if (!(typeof passwordStorage === 'undefined')) {
      setPassword(passwordStorage)

      //console.log(PASSWORDS[passwordStorage]);

      //console.log(ICONS)

      setCurrentEpisode(PASSWORDS[passwordStorage].episode)
      
      if(PASSWORDS[passwordStorage].episode>=6){
        setIsHacked(true)
      } 

      let episodeIcons = ICONS.filter(function (icon) {
          return  ((icon.parent === -1) &&(icon.episode <= PASSWORDS[passwordStorage].episode) && (icon.season <= PASSWORDS[passwordStorage].season ))
      });
      setIcons([...episodeIcons]);
      setCurrentBlogEntry(PASSWORDS[passwordStorage].episode)

      function handleResize() {
        if(parseInt(window.innerWidth) >= 800 && mobileMenuOpen){
          
          setMobileMenuOpen(false)

        }
      }

      function hashHandler(){
        //alert(window.location.hash)
        if(window.location.hash != ""){
          var icon = getIconFromType(window.location.hash, ICONS)
          if(icon != null) {
            displayModal(icon)
          }
          
          window.location.hash = ""
        }
        

      }

      window.addEventListener('hashchange', hashHandler, false);
      window.addEventListener("resize", handleResize);


       // Remove event listener on cleanup
       //return () => window.removeEventListener("resize", handleResize);

       return function cleanup() {
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("hashchange", hashHandler);
      };


    }else{
      history.push("/")
    }

  }, [history, mobileMenuOpen])


  const updateEpisode = newEpisode => {
    //console.log("updateEpisode "+newEpisode)
    setCurrentEpisode(newEpisode)

    let episodeIcons = ICONS.filter(function (icon) {
        return  ((icon.parent === -1) &&(icon.episode <= newEpisode) && (icon.isHidden != true) && (icon.season <= PASSWORDS[password].season ))
    });
    setIcons([...episodeIcons]);

    var event = new CustomEvent('UPDATEFOLDER', {detail :  { "newEpisode":newEpisode} });
    window.dispatchEvent(event);



  }

  const openInbox = e => {

    document.getElementById("body").style.overflowY = "hidden";
    setShowInbox(true)

  }

  const handleShowInboxToast = () => {
        //alert("showEmailSentToast")
        
        setShowToastInbox(!showToastInbox)
        
    }


  const closeInbox = e => {

    document.getElementById("body").style.overflowY = "auto";
    setShowInbox(false)

  }

  const openMenu = e => {
    document.getElementById("body").style.overflowY = "hidden";
    setMobileMenuOpen(true)

  }


  const closeMenu = e => {
    document.getElementById("body").style.overflowY = "auto";
    setMobileMenuOpen(false)

  }

  const handleTapBackLockedVideo = e => {
      if(e === "closeVideo"){
        setShowLockedVideo(false)
        return
      }
      if ( ((" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" overlaypw ") > -1) || ((" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" close-folderlogin ") > -1 ) ) {
        e.preventDefault()  
        setShowLockedVideo(false)
        
      }

  }

  const handleTapBack = name => {
    if(name === "blogPostFromReconTeam") {
      setShowBlogPostFromReconTeam(false)
      document.getElementById("body").style.overflowY = "auto";
    }else if(name === "electrical_interface") {
      setShowElectricalInterface(false)
      document.getElementById("body").style.overflowY = "auto";
      document.getElementById("body").style.overflowX = "auto";
    }
  }




  const handleClickIcon = e => {
         window.scrollTo({
          behavior: "auto",
          top:  0
        });

       var iconIndex =  e.target.id.substring(5, e.target.id.length);

       

       var iconId =  parseInt(e.target.id.substring(5, e.target.id.length));
       //console.log(iconIndex)
       //console.log(this.state.icons)
       //console.log("OPEN OBJECT "+iconId)



       let icon = ICONS[iconId]
       for( var i = 0; i < icons.length; i++){ 
          if ( parseInt(iconId) ===  icons[i].id) {
            icon = icons[i]
            //console.log(icon)
          }
        }

       displayModal(icon)
       

  }

  const triggerEmail = (message) => {
    inbox.current.triggerEmail(message)
  }

  const displayModal = (icon) => {

       if(icon.type === "external_url"){
        window.open(icon.url, "_blank")
        return

       }else if(icon.type === "blogPostFromReconTeam"){
          //console.log("devianttracker")
          document.getElementById("body").style.overflowY = "hidden";
          setShowBlogPostFromReconTeam(true)
       }else if(icon.type === "lockedVideoBWs1"){
          //console.log("lockedVideoBWs1")
          setShowLockedVideo(true)
          
       }else if(icon.type=== "electrical_interface") {
          document.getElementById("body").style.overflowY = "hidden";
          document.getElementById("body").style.overflowX = "hidden";
          setShowElectricalInterface(true)

          //


       }else {
          let copy_modals = [...modals];

          
          let isPresent=false
          for( var k = 0; k < copy_modals.length; k++){ 
            if ( copy_modals[k].id === icon.id) {
              isPresent = true
              let z = getMaxZModal(copy_modals)
              copy_modals[k].z = z+1;
            }
          }
          if(!isPresent){
            
            if (typeof icon.passwordprotected !== 'undefined' && store.get(icon.name) !== icon.password){
                  

                  //console.log("LALAL")

                  /*this.setState({showPwModal:true});
                  this.setState({selectedIconPw:icon})*/
                  
                  //return
            }


            var childrensIcon = getGetChildrenFromFolder(icon.id, ICONS)


            // if the new modal is from another modal, replace the parent modal w new modal
            if(icon.parent > -1 && icon.type==="folder") {
                //console.log("REPLACEMODAL")
                for( var j = 0; j < copy_modals.length; j++){ 
                  if(copy_modals[j].id === copy_modals.parent){
                    copy_modals[j].name = icon.name
                    
                    copy_modals[j].icons = childrensIcon
                    copy_modals[j].id = icon.id
                  }

                }

            }else{
                var width = 850
                var height = 525
                if (typeof icon.width !== 'undefined'){
                  width = icon.width
                }
                if (typeof icon.height !== 'undefined'){
                  height = icon.height
                }
                let left = (window.innerWidth - parseInt(width))/2
                let top = (window.innerHeight - parseInt(height))/2
                let z = getMaxZModal(modals) 
                if(modals.length >= 1){
                  left = 50 + copy_modals[modals.length-1].left
                  top = 55 + copy_modals[modals.length-1].top
                }
                if(icon.type === "electrical_interface" ) {
                  if(window.innerWidth > 948 ) {
                    left = (window.innerWidth - 948)/2;
                  }else{
                    left = 0;
                  }

                  if(window.innerHeight > 800 ) {
                    top = (window.innerHeight - 800)/2;
                  }else{
                    top = 0;
                  }

                }
                var modal = {"z":z+1,"type":icon.type, name:icon.name, icons:childrensIcon, id:icon.id, width:icon.width, height:icon.height, left:left, top:top, image_url:icon.imageURL, audio_url:icon.audioURL, video_url:icon.videoURL};
                copy_modals = copy_modals.concat(modal);
            }

          }

          //console.log(copy_modals)

          setModals(copy_modals);
       }

  }

  const handleSetTimerLogout = () => {
    //console.log("handleSetTimerLogout")
    setTimeout(() => {
      store.remove('passwordBW')
      history.push("/")
    }, 5000);



  }


  const handleCloseModal = dataModal => {
    var modalId =  dataModal.id;
    //console.log(modalId)
    var arr = [...modals];

    for( var i = 0; i < arr.length; i++){ 
       if ( arr[i].id === modalId) {
         arr.splice(i, 1); 
       }
    }
    //console.log(arr)
    setModals(arr);
    
    //console.log(this.state)
   }

   const handleUpdateZindex = dataModal => {
    //console.log("handleUpdateZindex")
      var modalId =  dataModal.id;
      var arr = [...modals];
      for( var i = 0; i < arr.length; i++){ 
         if ( arr[i].id === modalId) {
           let z = getMaxZModal(arr) 
           arr[i].z = z+1
         }
      }
      //console.log(arr)
      setModals(arr);


   }

   const getMaxZModal = (modals) => {
    if(modals.length === 0) return 0
    return modals.reduce((max, b) => Math.max(max, b.z), modals[0].z);
  }

  const clickPastBlogEntries = () => {

    setCurrentBlogEntry(currentBlogEntry - 1)
     /*window.scrollTo({
          behavior: "auto",
          top:  document.getElementById("blogcontent").offsetTop - document.getElementById("top-bar-menu").offsetHeight
        });*/
        document.getElementById("blair-witch-dekstop").scrollTo({
          behavior: "auto",
          top:  0
        });


  }

  const clickMenu = (name)  => {
    console.log("cli")
    
    /*window.scrollTo({
          behavior: "auto",
          top:  0
        });*/

    document.getElementById("blair-witch-dekstop").scrollTo({
          behavior: "auto",
          top:  0
        });


    setCurrentView(name)
  }

  const clickNewBlogEntries = () => {

    setCurrentBlogEntry(currentBlogEntry + 1)
    
   /*window.scrollTo({
          behavior: "auto",
          top:  document.getElementById("blogcontent").offsetTop - document.getElementById("top-bar-menu").offsetHeight
        });*/
        document.getElementById("blair-witch-dekstop").scrollTo({
          behavior: "auto",
          top:  0
        });


  }

  const openLighbox = (imageUrl) => {
    //console.log(imageUrl)

    setLightBoxImage(imageUrl)

  }

  var iconviews = icons.map((icon, index) => {
    //console.log("iconviews "+currentEpisode)
    return (
      <DesktopIcon icon={icon} key={index} iconSingleClick={handleClickIcon.bind(this)}  icons={ICONS} currentSeason={PASSWORDS[password].season} currentEpisode={currentEpisode}/> 
    )
  })


  const modalviews = modals.map((modal, index) => { 
      return (
        <Modal modal={modal} key={modal.id} currentEpisode={currentEpisode} closeModal={handleCloseModal} updateZindex={handleUpdateZindex}  iconSingleClick={handleClickIcon.bind(this)}/> 
      )  
  })
  
  return (<Div100vh id="body">
    <div id="blair-witch-dekstop"  className="app blair-witch-dekstop">

      <div className="">
          <div className="top-bar-menu" id="top-bar-menu">
            <div className="mobile-menu-icon"  onClick={openMenu}>
              <div></div>
              <div></div>
              <div></div>
            </div>
            

            <div className="menu desktop">
                <div className={`${(currentView === "message" ) ? "current" : ""}`} onClick={function(){clickMenu("message")}}>Message Boards</div>
                <div className={`${(currentView === "home" ) ? "current" : ""}`} onClick={function(){clickMenu("home")}}>Home</div>
                <div className={`${(currentView === "about" ) ? "current" : ""}`} onClick={function(){clickMenu("about")}}>About Us</div>
                <div className={`${(currentView === "investigations" ) ? "current" : ""}`}  onClick={function(){clickMenu("investigations")}}>Past Investigations</div>
            </div>
          
          </div>


          <img className="desktop site-logo" src="https://cdn.huntakiller.com/blair-witch/s2/bwp0201-superneutral-logo-header-desktop.svg" />
          <img className="mobile site-logo" src="https://cdn.huntakiller.com/blair-witch/s2/bwp0201-superneutral-logo-header-mobile.svg"/>

          <div className="flex blair-witch-content">

           


            <div className="user_profile">
              <div className="tipline-btn-container">
                <div className="tipline-btn" onClick={openInbox}><div>CHAT</div></div>
              </div>
            </div>

            {(currentView === "message") && (

              <div className="blogcontent" id="blogcontent"  >

                 {(password !== "") &&
                  <MessageBoard currentBlogEntry={currentBlogEntry} currentEpisode={currentEpisode} clickPastBlogEntries={clickPastBlogEntries.bind(this)} clickNewBlogEntries={clickNewBlogEntries.bind(this)} openLighbox={openLighbox.bind(this)} />
                  }
              </div>



            )}

            {(currentView === "home") && (

              <div className="blogcontent" id="blogcontent"  >
            
                <HomeView />
              </div>



            )}

            {(currentView === "about") && (

              <div className="blogcontent" id="blogcontent"  >
            
                <AboutView />
              </div>



            )}

            {(currentView === "investigations") && (

              <div className="blogcontent" id="blogcontent"  >
            
                <PastInvestigationsView openLighbox={openLighbox.bind(this)} />
              </div>



            )}

            
        
            <div className="desktopicons"><div className="desktopicons-content">{iconviews}</div></div>
        
    </div>
  </div>




      <Dock logout="true" />

      {modalviews}

    </div> 
    {isHacked && (<div className="glitch-right"><GlitchFx><div className="wierd-glitch-2-right"></div></GlitchFx></div>)}
    {isHacked && (<div className="glitch-right-1"><GlitchFx><div className="wierd-glitch-2-right"></div></GlitchFx></div>)}
    {isHacked && (<div className="glitch-right-2"><GlitchFx><div className="wierd-glitch-2-right"></div></GlitchFx></div>)}
    {isHacked && (<div className="glitch-right-3"><GlitchFx><div className="wierd-glitch-2-right"></div></GlitchFx></div>)}
              

    


       {lightBoxImage && (
          <Lightbox mainSrc={lightBoxImage} onCloseRequest={() => setLightBoxImage(null)} />
        )}


        {showLockedVideo && (
          <LockedVideo tapBack={handleTapBackLockedVideo.bind(this)} setTimerLogout={handleSetTimerLogout.bind(this)}/>
        )}

         {showBlogPostFromReconTeam && (
          <BlogPostFromReconTeam tapBack={handleTapBack.bind(this)} openLighbox={openLighbox.bind(this)} />
        )}

        {showElectricalInterface && (
          <ElectricalInterfaceView tapBack={handleTapBack.bind(this)}  triggerEmail={triggerEmail.bind(this)}/>
        )}


        {(password !== "") && (
          <div style={{display: showInbox  ? 'block' : 'none'}}>
            <Inbox tapBack={closeInbox.bind(this)} showInboxToast={handleShowInboxToast.bind(this)} currentSeason={PASSWORDS[password].season} currentEpisode={PASSWORDS[password].episode}  updateEpisode={updateEpisode.bind(this)}   ref={inbox}/>
          </div> 
        )}

        {showToastInbox  !== false && (
            <div className="HAKEmailChatContainer">
                <Toast className='hak-email-sent-toast pt-2 pb-2'   delay={6000} autohide  onClose={() => handleShowInboxToast()} style={{zIndex:1000000}}>
                                        
                    <Toast.Body className="text-light text-center pb-0"><h4>New Message</h4>You received a message!<br/>
                    <button className="btn text-info mt-2" onClick={handleShowInboxToast}>Got It!</button></Toast.Body>
                </Toast>
            </div>
        )}




    {(password !== "" && mobileMenuOpen === true ) && (
    <div style={{display: mobileMenuOpen  ? 'block' : 'none'}}>
      <MobileMenu tapBack={closeMenu.bind(this)} icons={icons} isHacked={isHacked}  allIcons={ICONS} currentSeason={PASSWORDS[password].season} currentEpisode={currentEpisode} currentView={currentView} iconSingleClick={handleClickIcon.bind(this)} clickMenu={clickMenu.bind(this)}/>
    </div>
    )}

        
    </Div100vh> 
  );
}