var icons = [{
          "id": 0,
          "name": "For Investigator",
          "icon": "s2/icons/bw0201-for-Investigator-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 1,
          "season":2,
          "type":"folder",
          "parent":-1,
        },
        {
          "id": 1,
          "name": "Before-Burkittsville-Audio.mp3",
          "icon": "s2/icons/bw0201-before-burkittsville-audio-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 1,
          "season":2,
          "type":"audio",
          "parent":0,
          "audioURL":"s2/audio/before-burkittsville.mp3"
        },
        {
          "id": 2,
          "name": "Interview-Audio.mp3",
          "icon": "s2/icons/bw0201-interview-audio-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 1,
          "season":2,
          "type":"audio",
          "parent":0,
          "audioURL":"s2/audio/interview-transcript.mp3"
        },
        {
          "id": 3,
          "name": "MAP-OF-BLACK-HILLs-FORest.jpg",
          "icon": "s2/icons/bw0201-map-of-black-hills-forest-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 1,
          "season":2,
          "type":"image",
          "parent":0,
          "imageURL":"s2/forinvestigators/map-of-black-hills-forest.jpg"
        },
        {
          "id": 4,
          "name": "Blog post",
          "icon": "s2/icons/bw0201-blog-post-from-recon-team-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 1,
          "season":2,
          "type":"blogPostFromReconTeam",
          "parent":0
        },
        {
          "id": 5,
          "name": "Text Between Emma and Ray",
          "icon": "s2/icons/bw0201-text-between-ray-&-emma-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 1,
          "season":2,
          "type":"image",
          "parent":0,
          "imageURL":"s2/forinvestigators/text-between-ray-emma.jpg"
        },
        
        {
          "id": 6,
          "name": "Official-Warning.jpg",
          "icon": "s2/icons/bw0202-official-warning-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 2,
          "season":2,
          "type":"image",
          "parent":0,
          "imageURL":"s2/forinvestigators/official-warning.jpg"
        },
        {
          "id": 7,
          "name": "Graffiti-Photo.jpg",
          "icon": "s2/icons/bw0202-graffiti-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 2,
          "season":2,
          "type":"image",
          "parent":0,
          "imageURL":"s2/forinvestigators/graffiti-photo.jpg"
        },
        {
          "id": 8,
          "name": "Electrical Interface",
          "icon": "s2/icons/bw0202-electrical-interface-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 2.2,
          "isHidden":true,
          "season":2,
          "type":"electrical_interface",
          "parent":0,
        },
        {
          "id": 9,
          "name": "Audio-From-Emmas-Phone.mp3",
          "icon": "s2/icons/bw0203-audio-from-emmas-phone-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 3,
          "season":2,
          "type":"audio",
          "parent":0,
          "audioURL":"s2/audio/emmas-phone-378342.mp3"
        },
        {
          "id": 10,
          "name": "Audio-From-Rays-Phone.mp3",
          "icon": "s2/icons/bw0203-audio-from-rays-phone-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 3,
          "season":2,
          "type":"audio",
          "parent":0,
          "audioURL":"s2/audio/rays-phone-3234234.mp3"
        },
        {
          "id": 11,
          "name": "Partial-Map.jpg",
          "icon": "s2/icons/bw0204-partial-map-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 4,
          "season":2,
          "type":"image",
          "parent":0,
          "imageURL":"s2/forinvestigators/partial-map.jpg"
        },
        {
          "id": 12,
          "name": "Carvings.jpg",
          "icon": "s2/icons/bw0204-carvings-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 4,
          "season":2,
          "type":"image",
          "parent":0,
          "imageURL":"s2/forinvestigators/carvings.jpg"
        },
        {
          "id": 13,
          "name": "Photos-From-Phone.jpg",
          "icon": "s2/icons/bw0204-photos-from-phone-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 4,
          "season":2,
          "type":"image",
          "parent":0,
          "imageURL":"s2/forinvestigators/photos-from-phone.jpg"
        },
        {
          "id": 14,
          "name": "Shutdown-Production-Letter.jpg",
          "icon": "s2/icons/bw0204-shutdown-production-letter-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 4,
          "season":2,
          "type":"image",
          "parent":0,
          "imageURL":"s2/forinvestigators/shutdown-production-letter.jpg"
        },
        {
          "id": 15,
          "name": "Photo-Of-Kents-Living-Room.jpg",
          "icon": "s2/icons/bw0205-photo-of-kents-living-room-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 5,
          "season":2,
          "type":"image",
          "parent":0,
          "imageURL":"s2/forinvestigators/photo-of-kents-living-room.jpg"
        },
        {
          "id": 16,
          "name": "Good-Fences-Post.jpg",
          "icon": "s2/icons/bw0205-good-fences-post-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 5,
          "season":2,
          "type":"image",
          "parent":0,
          "imageURL":"s2/forinvestigators/good-fences-post.jpg"
        },
        {
          "id": 17,
          "name": "Inspection-Notes.jpg",
          "icon": "s2/icons/bw0206-inspection-notes-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 6,
          "season":2,
          "type":"image",
          "parent":0,
          "imageURL":"s2/forinvestigators/inspection-notes.jpg"
        },
        {
          "id": 18,
          "name": "Dog-Collar.jpg",
          "icon": "s2/icons/bw0206-dog-collar-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 6,
          "season":2,
          "type":"image",
          "parent":0,
          "imageURL":"s2/forinvestigators/dog-collar.jpg"
        },
        {
          "id": 19,
          "name": "Tree-Carving.jpg",
          "icon": "s2/icons/bw0206-tree-carving-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 6,
          "season":2,
          "type":"image",
          "parent":0,
          "imageURL":"s2/forinvestigators/tree-carving.jpg"
        },
        {
          "id": 20,
          "name": "SuperNeutral-Recording.mp3",
          "icon": "s2/icons/bw0206-superneutral-recording-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 6,
          "season":2,
          "type":"audio",
          "parent":0,
          "audioURL":"s2/audio/superneutral-673475675.mp3"
        },
        {
          "id": 21,
          "name": "Video",
          "icon": "s2/icons/bw0206-video-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 6,
          "season":2,
          "type":"final_video",
          "parent":0,
          "videoURL":"https://embed-ssl.wistia.com/deliveries/2ee6b0d665d8173921ca7162a2a8d024f18fd543.bin"
          
        },
        {
          "id": 22,
          "name": "Transcribed-Audio.mp3",
          "icon": "s2/icons/bw0206-transcribed-audio-icon.svg",
          "top": 50,
          "left": 40,
          "episode": 6,
          "season":2,
          "type":"audio",
          "parent":0,
          "audioURL":"s2/audio/transcribed-837485495.mp3"
        }
    ]




export default icons;