import React, { useState } from 'react';


export default function MessageBoardEpisode1(props) {
  

  
  return (<>
    <div className="blog_entry">
    	<div className="blog_title">
    		<h2>Welcome to the Revamped SuperNeutral Message Boards!</h2>
    		<h3>@SNmoderator_nightofthelivinged</h3>
    	</div>

    	<div className="blog_text">
    		<p>Welcome, everyone, to the brand-spanking-new SuperNeutral message boards! James and I have had these cookin' for a while and thought, what better time to deploy them than with the announcement of our journey to Burkittsville! This is a place for all you fans to talk with one another, exchange theories, and just generally come together as a community. Don't feel limited to just stuff about Burkittsville, either, just so long as you remember to stay spooky!<br/><br/>(Obviously, any hate-speech, name-calling, or generally bad behavior will get you immediately raptured from this website)</p>
    	</div>
    </div>


    <div className="blog_entry">
    	<div className="blog_title">
    		<h2>Blair Witch: real or what?</h2>
    		<h3>@myBFFLisaghost</h3>
    	</div>

    	<div className="blog_text">
    		<p>Do you think the Blair Witch is real? I know there are historical figures and stuff, and there have been disappearances, but is it any more serious than something like the Bermuda Triangle? Isn't it true that people don't really disproportionately disappear there? What if this is kind of like the Bermuda Triangle but on land?</p>
    	</div>

    	<div className="thread-reply-container">

    		<div className="thread-reply">
    			<div className="inner-thread-reply ">
    				<p className="font-bold reply-username">@banshe72216</p>
    				<p className="reply-content">That's already a thing re Bennington and Bridgewater Triangles</p>
    			</div>
    		</div>

    		<div className="thread-reply">
    			<div className="inner-thread-reply ">
    				<p className="font-bold reply-username">@burkittsvillain</p>
    				<p className="reply-content">I've lived in Burkittsville my whole life and I can tell you the Blair Witch is 100% <u>real</u>. Don't joke.</p>
    			</div>
    		</div>

    		<div className="thread-reply">
    			<div className="inner-thread-reply ">
    				<p className="font-bold reply-username">@yallstayspooky</p>
    				<p className="reply-content">Tell me more!</p>
    			</div>
    		</div>

    		<div className="thread-reply">
    			<div className="inner-thread-reply ">
    				<p className="font-bold reply-username">@SNmoderator_nightofthelivinged</p>
    				<p className="reply-content">No spoilers!!!</p>
    			</div>
    		</div>

    		<div className="thread-reply">
    			<div className="inner-thread-reply ">
    				<p className="font-bold reply-username">@emmAlkonost</p>
    				<p className="reply-content">I guess we're about to find out... I'm new on the crew, and we'll be sending live updates from Burkittsville!</p>
    			</div>
    		</div>
    	</div>


    </div>


    <div className="blog_entry">
    	<div className="blog_title">
    		<h2>Paranormal experiences irl?</h2>
    		<h3>@banshe72216</h3>
    	</div>

    	<div className="blog_text">
    		<p>I know we all like to joke around sometimes, but for real, has anyone, aside from Ed and James, actually SEEN a ghost? Ghost, cryptid, monster, whatever it is, let's hear about it!</p>
    	</div>

    	<div className="thread-reply-container">

    		<div className="thread-reply">
    			<div className="inner-thread-reply ">
    				<p className="font-bold reply-username">@aliceinwonder24</p>
    				<p className="reply-content">I live with a ghost. There's one in my grandma's attic. We think it's the ghost of the guy who lived here two tenants ago. He's harmless, but he does steal stuff from us sometimes and we'll find it piled into a corner of the attic.</p>
    			</div>

    			<div className="thread-reply">
    				<div className="inner-thread-reply ">
    					<p className="font-bold reply-username">@SNmoderator_jamesalope</p>
    					<p className="reply-content">Are you sure it's not a raccoon?</p>
    				</div>
    			</div>

    			<div className="thread-reply">
    				<div className="inner-thread-reply ">
    					<p className="font-bold reply-username">@aliceinwonder24</p>
    					<p className="reply-content">There's always the possibility it's an escaped lunatic who likes watching me in my sleep ;)</p>
    				</div>		
    			</div>

    			<div className="thread-reply">
    				<div className="inner-thread-reply ">
    					<p className="font-bold reply-username">@dayneofthedead</p>
    					<p className="reply-content">One of my friends is a realtor and has this house they haven't been able to sell for years. They have to disclose there was a murder on the premisis; someone was killed in the attic and left tied up in the rafters like some kind of crazy marionnette. A bunch of people now just ask for showings so they can see the ghost. They say you can see the shadow of the victim silhouetted against the wall whenever lightning strikes. They never caught the guy who did it.</p>
    				</div>		
    			</div>
    		</div>


    		<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@thehauntedmaus</p>
					<p className="reply-content">I live near Lake Champlain and nearly all my friends and I have seen Champ on multiple occasions!</p>
				</div>		
			</div>

			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@SNmoderator_nightofthelivinged</p>
					<p className="reply-content">Hmmmm, this thread is giving me an idea for a SuperNeutral scary story contest...</p>
				</div>	

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@banshe72216</p>
						<p className="reply-content">YES.</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@aliceinwonder24</p>
						<p className="reply-content">ED!!!!</p>
					</div>		
				</div>

			</div>
    	</div>
	</div>



	<div className="blog_entry">
    	<div className="blog_title">
    		<h2>Thanks, Ed and James!</h2>
    		<h3>@gothandpescatarian</h3>
    	</div>

    	<div className="blog_text">
    		<p>I just wanted to say thank you to Ed and James for making such a great show. I just recently stumbled on SuperNeutral and have been going through a huge backlog of episodes on rides to and from the hospital to visit my grandparents. You guys have such a clean, fun time (and, darn, if you don't teach me stuff about folklore and history too!) it really helps me turn my brain off and just enjoy something in the midst of stress. Thanks a lot and keep making such awesome episodes!</p>
    	</div>

    	<div className="thread-reply-container">
			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@SNmoderator_nightofthelivinged</p>
					<p className="reply-content">That's why we do it!</p>
				</div>		
			</div>

			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@SNmoderator_jamesalope</p>
					<p className="reply-content">So glad SuperNeutral has been such an important and fun part of your life, @gothandpescatarian!</p>
				</div>		
			</div>


    	</div>
	</div>

	<div className="blog_entry">
    	<div className="blog_title">
    		<h2>Cryptid Wars</h2>
    		<h3>@dayneofthedead</h3>
    	</div>

    	<div className="blog_text">
    		<p>LET THEM FIGHT! What's a good monster fight you wanna see? Mine's Bigfoot vs. Jersey Devil.</p>
    	</div>

    	<div className="thread-reply-container">
			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@SNmoderator_nightofthelivinged</p>
					<p className="reply-content">Does this version of JD have satanic powers? Also, Bigfoot vs. thunderbird.</p>
				</div>	

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@emmAlkonost</p>
						<p className="reply-content">Not fair, one has thumbs.</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@SNmoderator_jamesalope</p>
						<p className="reply-content">Bunyip vs. logic.</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@SNmoderator_nightofthelivinged</p>
						<p className="reply-content">You ought to know better than to mess with Australia, James! They have actual dinosaurs there.</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@SNmoderator_jamesalope</p>
						<p className="reply-content">They do <u>not.</u></p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@SNmoderator_nightofthelivinged</p>
						<p className="reply-content">Say that to this face:<br/><br/>
						<img src="https://cdn.huntakiller.com/blair-witch/s2/bwp0201-casuarius-desktop.jpg"  alt="casuarius" onClick={() => props.openLighbox("https://cdn.huntakiller.com/blair-witch/s2/bwp0201-casuarius-desktop.jpg")}/>
						</p>

					</div>		
				</div>


			</div>


			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@thehauntedmaus</p>
					<p className="reply-content">Bigfoot v. yeti?</p>
				</div>		

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@RayvenMocker12399</p>
						<p className="reply-content">The legal battle.</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@dayneofthedead</p>
						<p className="reply-content">They're the same thing!</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@aliceinwonder24</p>
						<p className="reply-content">Wow, they are so not.</p>
					</div>		
				</div>

			</div>



    	</div>
	</div>

	<div className="blog_entry">
    	<div className="blog_title">
    		<h2>Bon voyage, James!</h2>
    		<h3>@therealmrsrebeccadurant</h3>
    	</div>

    	<div className="blog_text">
    		<p>I'm gonna miss you, honey! Good luck on your trip! Xoxo</p>
    	</div>

    	<div className="thread-reply-container">
			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@aliceinwonder24</p>
					<p className="reply-content">Omg that's so cute</p>
				</div>		
			</div>

			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@dayneofthedead</p>
					<p className="reply-content">Jealous</p>
				</div>		
			</div>

			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@aliceinwonder24</p>
					<p className="reply-content">Enjoy being hexed, Dayne.</p>
				</div>		
			</div>


    	</div>
	</div>

	<div className="blog_entry">
    	<div className="blog_title">
    		<h2>Help for a Newbie Code-Cracker</h2>
    		<h3>@bewarethelariosauro</h3>
    	</div>

    	<div className="blog_text">
    		<p>I just got a shortwave radio, and I've been picking up some pretty spooky sound bytes lately. I don't know why, but something tells me they're voices trying to talk to me. Not sure if they're encoded or what, but I thought I'd start trying to crack them.<br/><br/>Code and cipher folks, give me your crib notes!</p>
    	</div>

    	
    	<div className="thread-reply-container">
			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@aliceinwonder24</p>
					<p className="reply-content">Happy to help! So... there are lots of methods people use to encode information they don't want others to see.<br/><br/>If you're using the alphabet, you can either a) scramble the letters or b) create a cipher, where one letter stands in for another.<br/><br/>Two common ciphers are Atbash—where you map the alphabet onto a mirrored version of itself and decode (A becomes Z, etc.)—and the Caesar Shift—where you move each letter a certain number of places forward or back. You can also use a keyword to create a cipher, but that's a bit more complicated.<br/><br/>Codes work a little differently than ciphers, in that you use symbols to correspond to letters. Pigpen and Morse Code are probably two of the most famous examples.<br/>That's your first lesson, @bewarethelariosauro! And here's a pop quiz: Pmrggrmt Yvggb</p>
				</div>	

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@bewarethelariosauro</p>
						<p className="reply-content">PMed you my answer.</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@aliceinwonder24</p>
						<p className="reply-content">You are correct! I won't spoil it for the others, but here's a hint... If she offers to make me a sweater, my answer is no!</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@bewarethelariosauro</p>
						<p className="reply-content">I went back and wrote down some of the stuff I heard. At least what I think it is:<br/><br/>c-s-y-v-x-m-q-i-m-w-r-i-e-v-4<br/><br/>Thoughts?</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@aliceinwonder24</p>
						<p className="reply-content">Hmmm... that's weird. I'm having a lot of trouble decoding this. I'll keep you posted.</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@dayneofthedead</p>
						<p className="reply-content">She's lying. That one's easily cracked. She just didn't want to freak you out.</p>
					</div>		
				</div>
				

			</div>


    	</div>


	</div>

	<div className="blog_entry">
    	<div className="blog_title">
    		<h2>Odds</h2>
    		<h3>@dayneofthedead</h3>
    	</div>

    	<div className="blog_text">
    		<p>So, what do y'all think, are Ed and James going to survive Burkittsville?</p>
    	</div>

    	<div className="thread-reply-container">
			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@princessofdarqueness</p>
					<p className="reply-content">50/50</p>
				</div>		
			</div>

			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@aliceinwonder24</p>
					<p className="reply-content">Rude.</p>
				</div>		
			</div>


    	</div>
	</div>


    </> 
  );
}