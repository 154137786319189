import React, { useState, useEffect } from 'react';
import Div100vh from 'react-div-100vh'
import LightView from './LightView'
import FilamentVoltageView from './FilamentVoltageView'
import PlateCurrentView from './PlateCurrentView'
import RFLineCurrentView from './RFLineCurrentView'
import PlateVoltageView from './PlateVoltageView'
import VolumeView from './VolumeView'
import BandDisplay from './BandDisplay'
import PAVoltTuner from './PAVoltTuner'
import '../../css/electricalInteface.css'


import { Toast } from 'react-bootstrap';


export default function ElectricalInterfaceView(props) {
  
	const [filamentControlValue, setFilamentControlValue] = useState(false)
	const [filamentControlValue2, setFilamentControlValue2] = useState(false)
	const [filamentVoltageValue, setFilamentVoltageValue] = useState(false)
	const [plateCurrentValue, setPlateCurrentValue] = useState(false)
	const [rfLineCurrentValue, setRfLineCurrentValue] = useState(false)
	const [plateVoltageValue, setPlateVoltageValue] = useState(true)
	const [volumeValue, setVolumeValue] = useState(false)
	const [plateSwitchValue, setPlateSwitchValue] = useState(false)
	const [band1Value, setBand1Value] = useState(false)
	const [band2Value, setBand2Value] = useState(false)
	const [paVoltTunerValue, setPaVoltTunerValue] = useState(false)
	const [showToast, setShowToast] = useState(false)
	const [voltTunerRotation, setVoltTunerRotation] = useState(40)
	const [isCorrect, setIsCorrect] = useState(false)
	const updateFilamentVoltageValue = (value) => {
		setFilamentVoltageValue(value)
		
	}
	const updatePlateCurrentValue = (value) => {
		setPlateCurrentValue(value)
		
	}

	const updateRfLineCurrentValue = (value) => {
		setRfLineCurrentValue(value)
	}

	const updatePlateVoltageValue = (value) => {
		setPlateVoltageValue(value)
		
	}

	const updateVolumeValue = (value) => {
		setVolumeValue(value)
	}

	const updateBand1Value = (value) => {
		setBand1Value(value)
	}

	const updateBand2Value = (value) => {
		setBand2Value(value)
	
	}

	const updatePaVoltTunerValue = (value) => {
		setPaVoltTunerValue(value)
	}

	  const handleShowToast = () => {
        //alert("showEmailSentToast")
        
        setShowToast(!showToast)
        
    }



	const commit = () => {
		if(filamentControlValue && filamentVoltageValue && plateCurrentValue && 
			rfLineCurrentValue && plateVoltageValue && volumeValue && plateSwitchValue &&
			band1Value && band2Value && paVoltTunerValue) {
			//alert("SUCCESS")
			props.triggerEmail("electrical_interface_correct")
		} else{
			//alert("FAILURE")
			handleShowToast()
		}
	}

	const setIfCorrect = () => {
		setIsCorrect(filamentControlValue && filamentVoltageValue && plateCurrentValue && 
			rfLineCurrentValue && plateVoltageValue && volumeValue && plateSwitchValue &&
			band1Value && band2Value && paVoltTunerValue)
	}

	const updateFilamentControlValue = () => {
		if(!filamentControlValue) {
			setFilamentControlValue(!filamentControlValue)
			setFilamentControlValue2(true)
			const timer = setTimeout(() => {
				
			    setFilamentControlValue2(false);
			    clearTimeout(timer);
			}, 2000);
		}else{
			setFilamentControlValue(!filamentControlValue)
			setFilamentControlValue2(false)
		}
		

	}

	const updateVoltTunerRotation = (val) => {
		setVoltTunerRotation(val)
	}


	useEffect(() => {
	    // action on update of movies
	    setIfCorrect()

	}, [filamentControlValue,filamentVoltageValue,plateCurrentValue,rfLineCurrentValue,plateVoltageValue,volumeValue,plateSwitchValue,band1Value,band2Value,paVoltTunerValue ]);


  return (
  	<Div100vh  dontresize="true" className="h-full w-full flex flex-col blogPostFromReconTeamContainer electrical_interface_modal"> 
  		<div className="header">
			<h2>Electrical Interface</h2>
			<button className="close" onClick={function(){props.tapBack("electrical_interface")}}/>
		</div>

	   <div className="electricalInterface-container">
		   
		   <div className="electricalInterface-content">
			   <div className="filamentControl-container">
			   	
			   	<div className="topPart">
			   		<div className="light-container">
				   		<LightView isGreen={true} size="large" isOn={filamentControlValue}/>
				   		<LightView isGreen={false} size="large" isOn={filamentControlValue2} />
			   		</div>

			   		<button type="button" onClick={function(){updateFilamentControlValue()}}>Filament<br/>Control</button>
			   	</div>

			   	<div className="main-section">
			   		<FilamentVoltageView update={updateFilamentVoltageValue.bind(this)} filamentVoltageValue={filamentVoltageValue} />
			   		<PlateCurrentView update={updatePlateCurrentValue.bind(this)} plateCurrentValue={plateCurrentValue} />
			   		<RFLineCurrentView update={updateRfLineCurrentValue.bind(this)} rfLineCurrentValue={rfLineCurrentValue} />
			   		<PlateVoltageView update={updatePlateVoltageValue.bind(this)} plateVoltageValue={plateVoltageValue} voltTunerRotation={voltTunerRotation} />

			   		<PAVoltTuner update={updatePaVoltTunerValue.bind(this)} paVoltTunerValue={paVoltTunerValue} updateVoltTunerRotation={updateVoltTunerRotation.bind(this)} />
			   	</div>
			   
			   </div>


			   <div className="band-container">
			   		<div className="topPart">

			   			<VolumeView update={updateVolumeValue.bind(this)} volumeValue={volumeValue} />

			   			<button type="button"  onClick={function(){setPlateSwitchValue(!plateSwitchValue)}} >Plate Switch</button>
			   			<LightView isGreen={true} size="medium-large" isOn={plateSwitchValue} />
			   		</div>
			   		<div className="clear"></div>
			   		<BandDisplay commit={commit.bind(this)} isCorrect={isCorrect} band1Value={band1Value} band2Value={band2Value} updateBand1Value={updateBand1Value.bind(this)}  updateBand2Value={updateBand2Value.bind(this)} />

			   		<PAVoltTuner update={updatePaVoltTunerValue.bind(this)} paVoltTunerValue={paVoltTunerValue} updateVoltTunerRotation={updateVoltTunerRotation.bind(this)} />

			   </div>
			</div>

	   </div>

	   {showToast  !== false && (
            <div className="HAKEmailChatContainer">
                <Toast className='hak-email-sent-toast pt-2 pb-2'   delay={3000} autohide  onClose={() => handleShowToast()} style={{zIndex:1000000}}>
                                        
                    <Toast.Body className="text-light text-center pb-0"><h4>ERROR</h4>That doesn't seem right!<br/>
                    <button className="btn text-info mt-2" onClick={handleShowToast}>Try Again</button></Toast.Body>
                </Toast>
            </div>
        )}

	 </Div100vh>
  );
}
