import React from 'react';


import MessageBoardEpisode1 from './MessageBoardEpisode1'
import MessageBoardEpisode2 from './MessageBoardEpisode2'
import MessageBoardEpisode3 from './MessageBoardEpisode3'
import MessageBoardEpisode4 from './MessageBoardEpisode4'
import MessageBoardEpisode5 from './MessageBoardEpisode5'
import MessageBoardEpisode6 from './MessageBoardEpisode6'

 const components = {
    1: MessageBoardEpisode1,
    2: MessageBoardEpisode2,
    3: MessageBoardEpisode3,
    4: MessageBoardEpisode4,
    5: MessageBoardEpisode5,
    6: MessageBoardEpisode6,

};


export default function MessageBoard(props) {
  
	const Component = components[props.currentBlogEntry]

	const openLighbox = (imageUrl) => {
		props.openLighbox(imageUrl)
	}
  
  return (<>
    	<Component openLighbox={openLighbox.bind(this)} />


    	<div className="blog_nav">

            {(props.currentBlogEntry > 1) ? (<div className="pastBlogEntryBtn" onClick={props.clickPastBlogEntries}><img src="https://cdn.huntakiller.com/blair-witch/s2/back-arrow.svg" />&nbsp;&nbsp;Past Posts</div>) : (<div></div>) }

            {((props.currentBlogEntry < 6) && (parseInt(props.currentEpisode) > props.currentBlogEntry)) ? (<div className="newBlogEntryBtn" onClick={props.clickNewBlogEntries}>New Posts &nbsp;&nbsp;<img src="https://cdn.huntakiller.com/blair-witch/s2/next-arrow.svg" /></div>) : (<div></div>) }


        </div>


    </> 
  );
}