import React, { useState, useEffect } from 'react';
import LightView from './LightView'

import '../../css/electricalInteface.css'


export default function RFLineCurrentView(props) {
  
	const [rotation, setRotation] = useState(78)
	const [rotationMeter, setRotationMeter] = useState(33)
 	const [direction, setDirection] = useState ("up")
 	
 	const rotate = () => {

 		if(direction === "down") {
 			let newRotation = rotation - 26
 			let newRotationMeter = rotationMeter - 11
 			if(newRotation < -130 ){
 				newRotation = rotation + 26
 				newRotationMeter = rotationMeter + 11
 				setDirection("up")
	    	}
	    	setRotation(newRotation)
	    	setRotationMeter(newRotationMeter)

	    	if(newRotation === 52) {
	    		props.update(true)
	    	}else{
	    		props.update(false)
	    	}


 		}else {
 			let newRotation = rotation + 26
 			let newRotationMeter = rotationMeter + 11
 			if(newRotation > 130 ){
 				newRotation = rotation - 26
 				newRotationMeter = rotationMeter - 11
 				setDirection("down")
	    	}
	    	setRotation(newRotation)
	    	setRotationMeter(newRotationMeter)

	    	if(newRotation === 52) {
	    		props.update(true)
	    	}else{
	    		props.update(false)
	    	}
 		}

 		
 		
 	}

	return (<div className="filament-container">

		<div className="light-container">
	   		<LightView isGreen={true} size="medium" isOn={rotation != -130}/>
	   		<LightView isGreen={false} size="medium" isOn={rotation === -130} />
   		</div>

		<div className="meter-container">
			<div className="meterRFLineCurrent-base base">

				<img src="https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/dial.svg" className="dial"   style={{transform: `rotate(${rotationMeter}deg)`}} />


			</div>

			<h2>R. F. LINE CURRENT Meter</h2>
		</div>

		<div className="knob-container" >
		   	
		   	<div className="RFLineCurrent-base base">

		   		<img src="https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/knob.svg" className="knob" onClick={rotate} style={{transform: `rotate(${rotation}deg)`}} />

		   	</div>

		   	<h2>R. F. LINE CURRENT</h2>
		</div>
	</div>
	);
}
