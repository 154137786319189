import React, { useState, useEffect } from 'react';
import LockedVideoPasswordPopUp from './LockedVideoPasswordPopUp'
import ForgotPassword from './ForgotPassword'
import Modal from '../Modal'
import store from 'store';
export default function LockedVideo(props) {

	const [step, setStep] = useState(0);

  useEffect(() => { 
    //console.log("useEffect LockedVideo")
    const passwordStorage = store.get("LockedVideo")
    
    if (typeof passwordStorage != "undefined") {
      setStep(6)
    }

  }, [])



	const setCurrentStep = (step) => {
		setStep(step)
    if(step === 6){
      store.set('LockedVideo', true);
    }
	}


	const handleUpdateZindex = dataModal => {
	    return


   }

   const closeModal = dataModal => {
	    //console.log("CLOSE MODAL")
	    props.tapBack("closeVideo")


   }

	var modal = null;
	if(step === 6){
		let width = 850
        let height = 525
        
        let left = (window.innerWidth - parseInt(width))/2
        let top = (window.innerHeight - parseInt(height))/2
        
        

		modal =  {"z":10,"type":"video", name:"Hello", icons:[], id:9,videoURL:"http://distribution.bbb3d.renderfarming.net/video/mp4/bbb_sunflower_1080p_60fps_normal.mp4", width:null, height:null, left:left, top:top};
	}


    return (<>

    	{step === 0 && (
          <LockedVideoPasswordPopUp tapBack = {props.tapBack.bind(this)} setCurrentStep = {setCurrentStep.bind(this)} />
            
          
        )}

        {(step >= 1 && step <6) && (
          <ForgotPassword tapBack = {props.tapBack.bind(this)} setCurrentStep = {setCurrentStep.bind(this)} currentStep={step}/>
            
          
        )}


        {step === 6 && (

        	<div className="h-full w-full flex justify-center items-center z-10 overlaypw" onClick={props.tapBack}>
          		<Modal modal={modal} key={modal.id}  closeModal={closeModal} updateZindex={handleUpdateZindex.bind(this)} setTimerLogout={props.setTimerLogout}/>
            
          	</div>	
        )}


    	</>)
    
}
