import React, { useState, useEffect } from 'react';
import Div100vh from 'react-div-100vh'
export default function BlogPostFromReconTeam(props) {

	

    return (
    	<Div100vh  dontresize="true" className="h-full w-full flex flex-col blogPostFromReconTeamContainer"> 
    	
    		<div className="header">
    			<h2>BLOG POST FROM RECON TEAM</h2>
    			<button className="close" onClick={function(){props.tapBack("blogPostFromReconTeam")}}/>
    		</div>


    		<div className="just4FunSite-container flex-1 flex">
	        	<div className="blogPostFromReconTeamContent ">
	        		<div className="blogPostFromReconTeamContent-left">
	        			<h1>Wandering in the <br/>Witch's Woods!</h1>
	        			<h2>Calling all Edheads and Jwalkers!</h2>
	        			<p>It is I, Emma—your designated team herald! I'm here with my buddy Ray, sitting on an old fallen tree in the middle of the forest. That's right, the actual Black Hills Forest. James is pacing nearby, deep in thought, undoubtedly planning what he wants us to record next. And since we're taking a break from walking in circles, I figured it was the best time to give you all an update of what's been happening so far.<br/><br/>Our trip to Burkittsville was really fun. We played Ghost, and James shared his theory on the Frogman (future episode coming up!) as we drove past open fields. The closer we got to town, the more mysterious the air felt around us. It's hard to explain really, and of course Ray disagrees, but Ed swears he could feel a shift as we passed the welcome sign.<br/><br/>Before we even unpacked the van, James suggested a quick trek through the woods to grab some photos during the last hours of twilight (Which I fully supported. Motels are everywhere, but how often do you get to see a haunted forest?). James, Ray, and I elected to go while Ed stayed back in the hotel room prepping everything he wanted to get for the episode. Perhaps unsurprisingly to some, it only took a few hours before we got ourselves lost in here. For real! In trying to get back, we THOUGHT we were heading south the whole time, but it turns out James must not be as competent with a compass as he thought. We passed (at least twice) the same old rock where, the story goes, an entire search party was found slaughtered. Not exactly the most comforting campfire story.<br/><br/>It feels like we've tried almost every direction, but I assure you that we're doing okay for now. I haven't seen any rock cairns or witchy stick figures yet, so good news on that front. And besides, Ed's still out there talking to us. He's as relentless as usual, and honestly, I bet he's having his own field day in town.</p>
	        		
	        			<div className="videoContainer">

	        			<video  muted="muted" playsInline="" autoPlay="autoplay" loop preload="auto"><source src="https://cdn.huntakiller.com/blair-witch/s2/bw0201_superneutral-blog-gif.mp4 " type="video/mp4" /></video>
	        			</div>

	        			<p>Got to wrap this up; the look on James's face tells me that he's getting ready to go. Don't worry though. You know Ed and James—always the believer and the skeptic. If anyone can get us out of here, it'll be the two of them. One can only wonder how long it'll take. Wish us luck!<br/><br/>Signing off! —Emma<br/><br/>PS—Look forward to more behind-the-scenes material once we're out of here! This place is a goldmine!</p>
	        	</div>

	        		<div className="blogPostFromReconTeamContent-right">
	        			<h3>Photo Gallery</h3>

	        			<img src="https://cdn.huntakiller.com/blair-witch/s2/bwp0201-squirrel-desktop.jpg" onClick={function() {props.openLighbox("https://cdn.huntakiller.com/blair-witch/s2/bwp0201-squirrel-desktop.jpg")}} />

	        			<img src="https://cdn.huntakiller.com/blair-witch/s2/bwp0201-fern-desktop.jpg" onClick={function() {props.openLighbox("https://cdn.huntakiller.com/blair-witch/s2/bwp0201-fern-desktop.jpg")}} />

	        			<img src="https://cdn.huntakiller.com/blair-witch/s2/bwp0201-mushroom-desktop.jpg" onClick={function() {props.openLighbox("https://cdn.huntakiller.com/blair-witch/s2/bwp0201-mushroom-desktop.jpg")}} />


	        		</div>
	        	</div>

	        </div>

    	
    	</Div100vh>)
    
}
