var passwords = {
  CHUPACABRA: {
    season: 2,
    episode: 1,
  },
  SKUNKAPE: {
    season: 2,
    episode: 2,
  },
  YEREN: {
    season: 2,
    episode: 3,
  },
  CHAMP: {
    season: 2,
    episode: 4,
  },
  KRAKEN: {
    season: 2,
    episode: 5,
  },
  CHUCHUNYA: {
    season: 2,
    episode: 6,
  }
};




export default passwords;