import React, { useState, useEffect } from 'react';
import LightView from './LightView'
import ReactSlider from 'react-slider'

import '../../css/electricalInteface.css'



const correctImageURL = "https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/wavelengths/wave-correct.svg";

export default function BandDisplay(props) {
  
	const [value1, setValue1] = useState(0)
	const [value2, setValue2] = useState(0)
	useEffect(() => { 
		[1,2,3,4,5,6,7,8,9,10].forEach((index) => {
	        const img = new Image();
	        img.src = "https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/wavelengths/wave1-"+index+".svg";
    	});
    	[1,2,3,4,5,6,7,8,9,10].forEach((index) => {
	        const img = new Image();
	        img.src = "https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/wavelengths/wave2-"+index+".svg";
    	});

    	const img = new Image();
        img.src = correctImageURL;

	});
	return (<div className="band-display-container">
		<div className="band-display">
			{((value1 > 0 || (value1 === 10 && value2 !== 5) ) && !(value1 === 10 && value2 === 5)) && (
				<img src={"https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/wavelengths/wave1-"+value1+".svg"} />
			)}
			
			{((value2 > 0 || (value1 !== 10 && value2 === 5)) && !(value1 === 10 && value2 === 5)) && (
				<img src={"https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/wavelengths/wave2-"+value2+".svg"} />
			)}

			{(value1 === 10 && value2 === 5) && (

				<img src={correctImageURL} />
			)}
		</div>


		
		<div className = "slider-commit-container">
			<div>
				<div className="slider-container">
					<div >
						<ReactSlider
								step = {5}
							    className="horizontal-slider"
							    min = {0}
							    max = {10}
							    thumbClassName="thumb"
							    trackClassName="track"
							    renderThumb={(props, state) => <div {...props}></div>}
							    onChange={val => { setValue1(val); if(val === 10) {props.updateBand1Value(true)} else {props.updateBand1Value(false)}} }
							/>

						<img className="band-number" src="https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/band_number.svg"/>
				   	</div>

				   	<div className="light-container">
					   		<LightView isGreen={true} size="small" isOn={value1 != 0}/>
					   		<LightView isGreen={false} size="small" isOn={value1 === 0} />
				   	</div>
					

				</div>


				<div className="slider-container">
					<div >
						<ReactSlider
								step = {5}
							    className="horizontal-slider"
							    min = {0}
							    max = {10}
							    thumbClassName="thumb"
							    trackClassName="track"
							    renderThumb={(props, state) => <div {...props}></div>}
							    onChange={val => { setValue2(val); if(val === 5) {props.updateBand2Value(true)} else {props.updateBand2Value(false)}} }
							/>

						<img className="band-number" src="https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/band_number.svg"/>
				   	</div>

				   	<div className="light-container">
					   		<LightView isGreen={true} size="small" isOn={value2 != 0}/>
					   		<LightView isGreen={false} size="small" isOn={value2 === 0} />
				   	</div>

				</div>
			</div>

			<div>
				<img className="commit-button" src={props.isCorrect ? "https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/commit-button-on.svg" : "https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/commit-button-off.svg"}  onClick={function(){props.commit()}}/>
				<h2>Commit</h2>
			</div>
		</div>

		
		
	</div>
	);
}
