import React from 'react';


export default function MessageBoardEpisode5(props) {
  

  
  return (<>
    <div className="blog_entry">
    	<div className="blog_title">
    		<h2>SuperNeutral shutdown?</h2>
    		<h3>@gothandpescatarian</h3>
    	</div>

    	<div className="blog_text">
    		<p>Any news??? Did we ever find out if this was real?</p>
    	</div>

    	<div className="thread-reply-container">
			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@dayneofthedead</p>
					<p className="reply-content">Nothing beyond @hearseandcasket's post. Not exactly what I'd call proof.</p>
				</div>		
			</div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@hearseandcasket</p>
                    <p className="reply-content">You can believe it or not, Dayne. It's still happening.</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@dayneofthedead</p>
                    <p className="reply-content">Yeah, according to your cousin's sister's roommate's friend...</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@mandyfromknoxville</p>
                    <p className="reply-content">I'm going to start a petition, just in case.</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@gothandpescatarian</p>
                    <p className="reply-content">Good idea, Mandy! Pretty sure everyone on here will sign it.</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@myBFFLisaghost</p>
                    <p className="reply-content">Absolutely.</p>
                </div>      
            </div>


    	</div>
	</div>

    <div className="blog_entry">
        <div className="blog_title">
            <h2>Spotted something weird in a photo I took!!</h2>
            <h3>@everydarndayishalloween</h3>
        </div>

        <div className="blog_text">
            <p>Would y'all check out this photo? IS THIS THE JERSEY DEVIL?????<br/><br/>
            <img src="https://cdn.huntakiller.com/blair-witch/s2/bwp0205-Jersey-dog-photo-desktop.jpg"  alt="Jersey dog"  onClick={() => props.openLighbox("https://cdn.huntakiller.com/blair-witch/s2/bwp0205-Jersey-dog-photo-desktop.jpg")} /></p>
        </div>

        <div className="thread-reply-container">
            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@hearseandcasket</p>
                    <p className="reply-content">nah I don't think so</p>
                </div>    

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@everydarndayishalloween</p>
                        <p className="reply-content">Why not?</p>
                    </div>      
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@hearseandcasket</p>
                        <p className="reply-content">Looks like the Jersey Devil got his rabies shot! Good for him! <br/><br/>
                        <img src="https://cdn.huntakiller.com/blair-witch/s2/bwp0205-Jersey-dog-photo-zoom-desktop.jpg"  alt="Jersey dog zoom"  onClick={() => props.openLighbox("https://cdn.huntakiller.com/blair-witch/s2/bwp0205-Jersey-dog-photo-zoom-desktop.jpg")} /></p>
                    </div>      
                </div>  
            </div>


        </div>
    </div>

    <div className="blog_entry">
        <div className="blog_title">
            <h2>Bad visions have me concerned</h2>
            <h3>@someoneswatching92</h3>
        </div>

        <div className="blog_text">
            <p>Hey spooky family. As many of you know, I get weird premonitions from time to time. They started when I was a child, when an Angel touched my forehead and gifted me with the visions.<br/><br/>I've been having some about Ed and James. Weird, isolated images that aren't connected to anything else. I think the universe is trying to speak to me. I've seen them becoming trees. Surrounded by the woods. Some huge shadow looming above them. Have they put out any content since going to Burkittsville? It feels like we haven't heard from them in a while, and I'm worried. Especially after hearing that their show might be canceled. That's one of the places where the forces of darkness seem much stronger.</p>
        </div>

        <div className="thread-reply-container">
            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@dayneofthedead</p>
                    <p className="reply-content">Everyone just ignore this. None of @someoneswatching92's "premonitions" ever pan out.</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@burkittsvillain</p>
                    <p className="reply-content">I wouldn't be so sure. I live there. People who go in don't come out.</p>
                </div>      
            </div>

            <div className="thread-reply">
                <div className="inner-thread-reply ">
                    <p className="font-bold reply-username">@nessietonosferatu</p>
                    <p className="reply-content">I feel so powerless. What can we do to help?</p>
                </div>   

                <div className="thread-reply">
                    <div className="inner-thread-reply ">
                        <p className="font-bold reply-username">@burkittsvillain</p>
                        <p className="reply-content">Honestly? I wish I knew.</p>
                    </div>      
                </div>
               
            </div>



        </div>
    </div>

    </> 
  );
}