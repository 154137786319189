import React from 'react';

import '../../css/electricalInteface.css'


export default function LightView(props) {
  

  var image_url = props.isGreen ? "https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/green-light-glow.svg" : "https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/red-light-glow.svg";



  return (


   <div className={props.isGreen ? ("light-content green-light "+props.size) : ("light-content red-light "+props.size)} >
	   
	   {props.isOn && (<img src={image_url}/>)}

	   <p>{props.isGreen ? "On" : "Off"}</p>
   </div>
  );
}
