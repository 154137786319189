import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player'
import store from 'store';

import { sendKlaviyoEvent } from '../utils/klaviyo.js';

/*
To get the VIDEO URLS go to the url : https://fast.wistia.com/embed/medias/VIDEO_ID.jsonp
And select the .bin url and replace .bin by .mp4
;) 
NEED TO UPDATE THE START OF THE PASSWORD OBJECT BELLOW TO REFLECT THE ACTUALLY TIME THE VIDEO SHOULD START AT
*/

const PASSWORDS = [{"password":"STARE",'placeholder':"_ _ _ _ _", "videoURL":"https://embed-ssl.wistia.com/deliveries/00c899f2e192dda473d9e4ec245d9690.mp4", "start":0},
{"password":"HELPLESSLY",'placeholder':"_ _ _ _ _ _ _ _ _ _", "videoURL":"https://embed-ssl.wistia.com/deliveries/eca917327e8cb2ac23db61ede57244d8.mp4", "start":2},
{"password":"INTO THE DARK",'placeholder':"_ _ _ _ _ _ _ _ _ _ _ _ _", "videoURL":"https://embed-ssl.wistia.com/deliveries/00137892170108e30fd671db94dcc2a0.mp4", "start":4},
{"videoURL":"https://embed-ssl.wistia.com/deliveries/d3b723377d7ef317d0805b4d8fc97aa4.mp4", "start":6}]


export default function FinalVideoViewer(props) {

	const [isPlaying, setIsPlaying] = useState(false)
	const videoPlayer = useRef(null);
	
	const [showVideo, setShowVideo] = useState(true)
	const [currentStep, setCurrentStep] = useState(0)
    const [password, setPassword] = useState("");
    const [errorPw, setErrorPw] = useState(false);
    const [isReady, setIsReady] = useState(false);


	const onEndedVideo = (e) => {
		console.log("onEndedVideo")
		setIsPlaying(false)
		if(typeof PASSWORDS[currentStep].password !== "undefined"){
			setShowVideo(false)
			setIsReady(false)

		} else {
			const userEmail = store.get("userEmailBW");
			console.log('Correct');
            sendKlaviyoEvent("6",userEmail);
            var event = new CustomEvent('CHARACTERRESPONSE', {detail :  { "season":"bw02", "episode":"06", "success": "bw0206"} });
            window.dispatchEvent(event);
		}


		

	}

	const onReadyVideo = (e) => {
		if(!isReady) {
			setIsReady(true)
			videoPlayer.current.seekTo(PASSWORDS[currentStep].start)
		}
	}

	const onPlayVideo = (e) => {
		setIsPlaying(true)

	}

	const onPauseVideo = (e) => {
		setIsPlaying(false)
	}

	

	//console.log(props.video_url)
	const handleSubmit = (event) => {

	    event.preventDefault();
	    document.getElementById("password").blur();

	    if(PASSWORDS[currentStep].password === password.toUpperCase()){
	      setCurrentStep(currentStep+1)
	      setShowVideo(true)
	      setPassword("")
	      
	    }else{
	      setErrorPw(true)
	    }

  	}

  	const handleChange = (event) => {
	    setPassword(event.target.value)
	    setErrorPw(false)  
	}

	const handleKeyPress = (event) => {
	    if(event.key === 'Enter'){
	      document.activeElement.blur();
	      event.preventDefault();
	    }
	}


    return (
    	<>

    	{showVideo ? (
    		<div className="videoViewer">
    			<ReactPlayer ref={videoPlayer} controls={true} url={PASSWORDS[currentStep].videoURL} playing={isPlaying} onReady={onReadyVideo} onEnded={onEndedVideo} onPlay={onPlayVideo} onPause={onPauseVideo}/>
    		</div>

    	) : (
    		<div className="flex justify-center items-center flex-1">
	    		<div className="login-box rounded-lg flex flex-col justify-center items-center p-4 pt-10 pb-8 password-final-video">
	           
	            
	            

		            <form className=" rounded  pt-6 " onSubmit={handleSubmit}>
						<div className="">

							<div className=" mb-3">
								<input className="shadow appearance-none border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={password}
							onChange={handleChange} type="text" autoFocus id="password" placeholder={PASSWORDS[currentStep].placeholder}  maxLength={PASSWORDS[currentStep].password.length} onKeyPress={handleKeyPress}/>
								<h2 className="mt-3">PASSWORD</h2>
								{errorPw && (<p className="error mt-2">Invalid Password</p>)}
							</div>
						</div>

						<div className="flex items-center justify-between">
							<button className="bg-yellow mx-8  w-full text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" id="episodeLogin" type="submit" disabled={false}>
								ENTER
							</button>
						</div>
					</form>

		        </div>
		    </div>

    	)}
    	
    	

    	
    	</>)
    
}
