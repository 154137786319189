import React, { useState, useEffect } from 'react';
import store from 'store';
import Div100vh from 'react-div-100vh';

export default function MobileMenu(props) {
    const [icons, setIcons] = useState(props.icons);

    useEffect(() => { 
        const myIcons = [...icons];

        myIcons.forEach(function(ico, index){
            if(props.currentEpisode && ico.parent === -1) {
            
                const hasBeenOpen = store.get("BW0"+props.currentSeason+"0"+props.currentEpisode+"folderID"+ico.id)
        
                
                if (typeof hasBeenOpen === 'undefined') {
                    const allicons = props.allIcons
                    if(props.currentEpisode === ico.episode && ico.isHidden !== true) {
                        myIcons[index].isNew = true
                    }else{
                        //console.log(icons)
                        allicons.forEach(function(i){
                            
                            if(i.parent === ico.id && i.episode === props.currentEpisode && i.isHidden !== true) {
                                myIcons[index].isNew = true
                                return
                            }
                        })
                    }

                }
                
            }


        });

        setIcons(myIcons)



        window.addEventListener('UPDATEFOLDER', function (e) { 
            var episode = props.currentEpisode
            if(e.detail.newEpisode) {
                episode = e.detail.newEpisode
            }

            myIcons.forEach(function(ico, index){
                if(props.currentEpisode && ico.parent === -1) {
                
                    const hasBeenOpen = store.get("BW0"+props.currentSeason+"0"+episode+"folderID"+ico.id)
            
                    
                    if (typeof hasBeenOpen === 'undefined') {
                        const allicons = props.allIcons
                        if(episode === ico.episode && ico.isHidden !== true) {
                            myIcons[index].isNew = true
                        }else{
                            //console.log(icons)
                            allicons.forEach(function(i){
                                
                                if(i.parent === ico.id && i.episode === episode && i.isHidden !== true) {
                                    myIcons[index].isNew = true
                                    return
                                }
                            })
                        }

                    }else {
                        myIcons[index].isNew = false
                    }
                    
                }


            }); 
       }, false);

       
    }, [])
    

    const clickIcon = (e) => {
        var iconId =  parseInt(e.target.id.substring(5, e.target.id.length));

        const myIcons = [...icons];

        myIcons.forEach(function(ico, index){
            if(myIcons[index].isNew && myIcons[index].id === iconId) {
                myIcons[index].isNew = false

                store.set("BW0"+props.currentSeason+"0"+props.currentEpisode+"folderID"+iconId, true);


                var event = new CustomEvent('UPDATEFOLDER', {detail :  { "folder":"BW0"+props.currentSeason+"0"+props.currentEpisode+"folderID"+iconId} });
                window.dispatchEvent(event);

            }


        })


        setIcons(myIcons)

        props.tapBack(); 
        props.iconSingleClick(e)
    }


    const iconviews = icons.map((icon, index) => {
        return (
          <li key={index}  id={`icon-${icon.id}`} onClick={clickIcon}>
            <div id={`icon-${icon.id}`} >
                <span id={`icon-${icon.id}`} >{icon.name}{icon.isNew && (<img src="https://cdn.huntakiller.com/blair-witch/s1/exclamation-bubble.svg" className="exclamation-bubble" id={`icon-${icon.id}`} />)}
</span>
                
                <div className="clear"></div>
            </div>

           

            

         </li> 
        )
      })



    return (
        <Div100vh className="mobile-menu">
            <div>
            	<button className="close" onClick={props.tapBack}></button>
                
                <ul>
                    <li className={`${(props.currentView === "home" ) ? "current" : ""}`} onClick={function(){props.tapBack(); props.clickMenu("home");}} ><div>Home</div><div className="divider"></div></li>
                    <li className={`${(props.currentView === "message" ) ? "current" : ""}`} onClick={function(){props.tapBack(); props.clickMenu("message");}}><div>Message Boards</div><div className="divider"></div></li>
                    <li className={`${(props.currentView === "about" ) ? "current" : ""}`} onClick={function(){props.tapBack(); props.clickMenu("about");}}><div>About Us</div><div className="divider"></div></li>
                    <li className={`${(props.currentView === "investigations" ) ? "current" : ""}`} onClick={function(){props.tapBack(); props.clickMenu("investigations");}}><div>Past Investigations</div><div className="divider"></div></li>
                    {iconviews}
                </ul>
            </div>
        </Div100vh>
    )
    
}
