import React, { useState, useEffect } from 'react';
import LightView from './LightView'

import '../../css/electricalInteface.css'


export default function FilamentVoltageView(props) {
  
	const [rotation, setRotation] = useState(-90)
	const [rotationMeter, setRotationMeter] = useState(-37)
 	const [direction, setDirection] = useState ("down")
 	
 	const rotate = () => {

 		if(direction === "down") {
 			let newRotation = rotation - 45
 			let newRotationMeter = rotationMeter - 18.5
 			if(newRotation < -135 ){
 				newRotation = rotation + 45
 				newRotationMeter = rotationMeter + 18.5
 				setDirection("up")
	    	}
	    	setRotation(newRotation)
	    	setRotationMeter(newRotationMeter)
	    	//console.log(newRotation)
	    	if(newRotation === 90) {
	    		props.update(true)
	    	}else{
	    		props.update(false)
	    	}
 		}else {
 			let newRotation = rotation + 45
 			let newRotationMeter = rotationMeter + 18.5
 			if(newRotation > 135 ){
 				newRotation = rotation - 45
 				newRotationMeter = rotationMeter - 18.5
 				setDirection("down")
	    	}
	    	setRotation(newRotation)
	    	setRotationMeter(newRotationMeter)
	    	//console.log(newRotation)
	    	if(newRotation === 90) {
	    		props.update(true)
	    	}else{
	    		props.update(false)
	    	}
 		}





 		
 		
 	}

	return (<div className="filament-container">

		<div className="light-container">
	   		<LightView isGreen={true} size="medium" isOn={rotation != -135}/>
	   		<LightView isGreen={false} size="medium" isOn={rotation === -135} />
   		</div>

		<div className="meter-container">
			<div className="meterVoltage-base base">

				<img src="https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/dial.svg" className="dial"   style={{transform: `rotate(${rotationMeter}deg)`}} />


			</div>

			<h2>Filament Voltage</h2>
		</div>

		<div className="knob-container" >
		   	
		   	<div className="filamentVoltage-base base">

		   		<img src="https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/knob.svg" className="knob" onClick={rotate} style={{transform: `rotate(${rotation}deg)`}} />

		   	</div>

		   	<h2>Filament Voltage</h2>
		</div>
	</div>
	);
}
