import React, { useState } from 'react';
import GlitchFx from 'react-glitch-fx/lib/GlitchFx';

import FORGOTPASSWORDANSWERS from '../../datas/lockedVideo.js';

export default function ForgotPassword(props) {

	const [error, setError] = useState(false);
	const [userAnswer, setUserAnswer]  = useState("");

	/*const tapForgotPw = () => {
		console.log("tapForgotPw")
		props.setCurrentStep(1)
	    
	}*/


  const handleChange = (event) => {
    setUserAnswer(event.target.value)
    setError(false)
    
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      //console.log('enter press here! ')
      document.activeElement.blur();
      event.preventDefault();
    }
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    document.getElementById("userAnswer").blur();
    

    if(FORGOTPASSWORDANSWERS[props.currentStep].correct.includes(userAnswer.toLowerCase())){
    	props.setCurrentStep(props.currentStep+1);
    	setError(false);
    	setUserAnswer("");
    	document.getElementById("userAnswer").focus();
    }else if(userAnswer.length > 0){

    	setError(true)
    }

   

  }


 // console.log(props.currentStep)
	

    return (<div className="h-full w-full flex justify-center items-center z-10 overlaypw" onClick={props.tapBack}>
    			{(props.currentStep >= 4 && props.currentStep < 5) && (<div className="glitch-password-5"><GlitchFx><div className="wierd-glitch-2-right"></div></GlitchFx></div>)}
    			 {props.currentStep >= 5 && (<div className="glitch-password-6"><GlitchFx><div className="wierd-glitch-2-right"></div></GlitchFx></div>)}
    

	        	<div className="login-box forgot-password-box rounded-lg flex flex-col justify-center items-center p-4 pt-10 pb-8">
	        		<div className="mr-2 "><img onClick={props.tapBack} className=" close-icon-modal close-folderlogin"  alt="close-dt" src="https://cdn.huntakiller.com/huntakiller/s5/dt-close-button.svg"/></div>
	        	
		        	<h1 className=" mb-5 text-3xl	text-center" >Forgot Your Password?</h1>
		        	<p className=" mb-1 text-center text-sm italic text-white" >Answer the following  security questions to access this file.</p>
		        	<p className="  text-center text-white  txt-yellow" >{FORGOTPASSWORDANSWERS[props.currentStep].question}</p>

		        	<form className=" rounded  pt-6 px-0 "  onSubmit={handleSubmit}>
		        		<div className="">
		        			
              				<div className=" mb-3">
			        			<input autoFocus className="shadow appearance-none border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="userAnswer" type="text"  value={userAnswer}
              onChange={handleChange} onKeyPress={handleKeyPress}/>
			        			<p className="error mt-2">{error && "Invalid Response"} &nbsp; </p>
              				</div>

              				
		        		</div>
		        		<div className="flex items-center justify-between">
					      <button className="bg-grey mx-8  w-full text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" disabled={false}>
					        SUBMIT
					      </button>
					     
					    </div>
		        	</form>
		        	
		        </div>


		        {(props.currentStep >= 2 && props.currentStep < 4) && (<div className="glitch-password"><GlitchFx><div className="wierd-glitch-2-right"></div></GlitchFx></div>)}
    			 {(props.currentStep >= 2 && props.currentStep < 5) && (<div className="glitch-password-2"><GlitchFx><div className="wierd-glitch-2-right"></div></GlitchFx></div>)}
    	{(props.currentStep >= 3 && props.currentStep < 4) && (<div className="glitch-password-3"><GlitchFx><div className="wierd-glitch-2-right"></div></GlitchFx></div>)}
    			 {(props.currentStep >= 3 && props.currentStep < 5) && (<div className="glitch-password-4"><GlitchFx><div className="wierd-glitch-2-right"></div></GlitchFx></div>)}
    
	        </div>)
    
}
