import React from 'react';


export default function AboutView() {
  

  
  return (<>
  	 <div className="aboutSection">
    	<h1>"The pint-sized paranormal podcast that pits a believer… against a skeptic."</h1>
    	<p>Here at SuperNeutral, we're dedicated to getting to the truth of all the strangest figures and events out there. Whether they're tales of UFOs or the chupacabra, Bigfoot or the Bridgewater Triangle, they're all stories that push our understanding of the unknown, of what is and isn't possible—stories that have grown popular (or in some cases flat-out unstoppable) over the years. So, where did they come from? How did they grow? And which of them are true?</p>
    
    	<div className="flex">
    		<div>
    			<h1>Ed Barrett</h1>
    			<img src="https://cdn.huntakiller.com/blair-witch/s2/bwp0201-ed-barrett-headshot-desktop.jpg"  alt="ed barrett" />
    			<p>The word "theory" really means it's just the best explanation we have for some observable phenomenon. In school, we were taught to never presume we're right about anything. We're meant to always search for the truth, for the best explanation, and the best explanation about this world right now is that there's just no way it's as simple as it seems at first glance.</p>
    		</div>

    		<div>
    			<h1>James Durant</h1>
    			<img src="https://cdn.huntakiller.com/blair-witch/s2/bwp0201-james-durant-headshot-desktop.jpg"  alt="james durant" />
    			<p>I have always acknowledged that these stories exist for a reason. The reason just so happens to be the simpler one—people's willingness to conflate the unknown and the supernatural. The idea that a creak in the floorboards is more likely to be a spirit of some kind rather than be the result of a settling house. While it is fun to imagine monsters and ghouls around every corner, the reality is that it is much simpler to trust in the proof. In short, Occam's razor.</p>
    		</div>
    	</div>
    </div>
    </> 
  );
}