import React  from 'react';


export default function MessageBoardEpisode2(props) {
  

  
  return (<>
    <div className="blog_entry">
    	<div className="blog_title">
    		<h2>Converting the masses { '>' }:)</h2>
    		<h3>@idobelieveingene</h3>
    	</div>

    	<div className="blog_text">
    		<p>Hey y'all! New fan here. So, I've been trying to convince my wife to get in on the SuperNeutral fandom, but she just isn't budging! I'm DEFINITELY the believer of us two. I think she would really like James and his "logical arguments," but every time I try to get her to listen to the show, she says it's just too much for her. She thinks the show is for "whackjobs and weirdos." How do I prove her wrong? Thanks!</p>
    	</div>

    	<div className="thread-reply-container">
			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@therealmrsrebeccadurant</p>
					<p className="reply-content">It took a while for James to convince me to look into this stuff too. Don't worry! I'm sure she'll come around if it's something you're really passionate about :) I sure did.</p>
				</div>		
			</div>

			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@gothandpescatarian</p>
					<p className="reply-content">Ed & James have really helped me through some dark and difficult times in the past, so perhaps tell her that it's not just the cryptids that made us fans, but the people too.</p>
				</div>		
			</div>


    	</div>
	</div>

	<div className="blog_entry">
    	<div className="blog_title">
    		<h2>is there an episode this week?</h2>
    		<h3>@tillproven87</h3>
    	</div>

    	<div className="blog_text">
    		<p>i've been sick for the past few weeks so i've been playing catch up. was there something i missed?</p>
    	</div>

    	<div className="thread-reply-container">
			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@thehauntedmaus</p>
					<p className="reply-content">Uh, yeah! SuperNeutral's in Burkittsville getting real proof of the Blair Witch <span role="img" aria-label="mage-emoji">🧙</span><br/>While they're on hiatus, you should listen to their guest hosts, Kristen and Shawn! They're awesome!</p>
				</div>	

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@banshe72216</p>
						<p className="reply-content">I am solidly convinced that there isn't actually a witch there at all.</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@thehauntedmaus</p>
						<p className="reply-content">what else would it be?</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@banshe72216</p>
						<p className="reply-content">Literally anything else? Weird disappearances happen all over the globe - even over water. And I doubt there are THAT many witches who know how to swim. That was a way to kill them, after all.</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@gothandpescatarian</p>
						<p className="reply-content">Well no one really knows what's supposedly happening in the woods</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@nessietonosferatu</p>
						<p className="reply-content">Oo, I've been thinking A LOT about this over the years, and what if it's not a witch but like the woods themselves? There's this forest somewhere, I don't remember what it's called, that's supposed to be one giant, living organism! It can even move around for better resources and such. Naturally, it takes years, but wouldn't it be really cool if it could move like that over the course of a day?<br/>Edit: I found it! It's Pando! Check it out if you haven't, even outside of all the cryptid stuff, it's really cool.</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@iamthechupatrooper</p>
						<p className="reply-content">That would be awesome, but if that were true, I think someone would have figured it out already though.</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@dayneofthedead</p>
						<p className="reply-content">Here me out, what if there is a witch, but it's taken the form of something else? Someone else? Spooky, right?</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@aliceinwonder24</p>
						<p className="reply-content">Yeah, real spooky <span role="img" aria-label="rolling-eyes-emoji">🙄</span> Isn't the simplest explanation that it's actually just a witch tho?</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@dayneofthedead</p>
						<p className="reply-content">Open your mind, Alice</p>
					</div>		
				</div>

			</div>


    	</div>
	</div>

	<div className="blog_entry">
    	<div className="blog_title">
    		<h2>Weird Lines/Markings</h2>
    		<h3>@gothandpescatarian</h3>
    	</div>

    	<div className="blog_text">
    		<p>I'll be honest, usually, I am on the other side of the paranormal debate and would still consider myself to be more skeptical than not... However, I'm freaking out a little bit. This morning, I woke up with these strange lines on my neck and chest - and before anyone says it's from the sheets, it's cold as all hell where I live and I was wearing a hoodie to bed.<br/><br/>These lines went from the base of the right side of my chin and extended well past my pecs. They were a dark purple color, almost like a bruise, but unnatural. When I looked in the mirror, it loosely resembled an S, but barely. I would've taken a picture, but my phone was dead, and the marks were gone by the time it came on again.<br/><br/>Is this the sign of a curse or some other entity trying to tell me something? Thanks.</p>
    	</div>

    	<div className="thread-reply-container">
			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@thehauntedmaus</p>
					<p className="reply-content">You might want to call in a priest just to be certain. If there isn't one near you, read out the Lord's Prayer a few times or something.</p>
				</div>		
			</div>

			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@someoneswatching92</p>
					<p className="reply-content">The Angels are still there. Tho a Demon may not be far behind.</p>
				</div>		
			</div>

			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@banshe72216</p>
					<p className="reply-content">Dang, without a picture, it's really hard to know what exactly you're talking about</p>
				</div>	

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@gothandpescatarian</p>
						<p className="reply-content">Yeah... I'll get it next time.</p>
					</div>		
				</div>

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@hearseandcasket</p>
						<p className="reply-content">if there is a next time</p>
					</div>		
				</div>

			</div>



    	</div>
	</div>

	<div className="blog_entry">
    	<div className="blog_title">
    		<h2>Real ghost, Real Conversation</h2>
    		<h3>@myBFFLisaghost</h3>
    	</div>

    	<div className="blog_text">
    		<p>Alright nerds, I think I take the cake when it comes to talking to ghosts. I already had a long standing record using my little radio to do some scavenging in my area (I once found a haunted underground tunnel!), but now I've had a full fledged conversation with a ghost.<br/><br/>So there's this rail station not too far from my flat, and I had been scoping it out for a while, seeing if it's actually abandoned or just not well managed. Well, turns out, no one's used it in over 80 years. And the reason why is a terrible accident that happened there (apparently the train derailed). And that means there's got to be quite a lot of paranormal activity there, right?<br/><br/>I grabbed all my equipment today and ran down there as fast as I could. I set up shop and just listened for a good while. The static was weirdly rhythmic so I pulled out my SuperNeutral notebook (thanks Ed & James!) to compare what I was hearing to Morse code. What I got was this:<br/><br/>.. .- .---<br/><br/>I assume their name is AJ? So I started talking to them. At first, it was just the same static noise, but after a bit (I'll save you the decoding), I heard "hello" and "you there?" and "pal". I'm pretty sure we just became instant friends.</p>
    	</div>

    	<div className="thread-reply-container">
			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@everydarndayishalloween</p>
					<p className="reply-content">That's so cool! You'll have to say hi for me!</p>
				</div>	

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@myBFFLisaghost</p>
						<p className="reply-content">Will do</p>
					</div>		
				</div>

			</div>

			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@someoneswatching92</p>
					<p className="reply-content">Watch for those who act friendly at first. The Angels see all.</p>
				</div>		
			</div>


    	</div>
	</div>

	<div className="blog_entry">
    	<div className="blog_title">
    		<h2>SOS: Calling All Code-Breakers!</h2>
    		<h3>@mandyfromknoxville</h3>
    	</div>

    	<div className="blog_text">
    		<p>Hi everyone! This is my first time posting, although I've been a "lurker" for many years. My two boys are big SuperNeutral fans and love all things paranormal, plus anything to do with codes and ciphers.<br/><br/>Speaking of which, I know some of y'all are really good with decoding and stuff, so I was hoping you could help me out. My son Josh is at college right now, and he often sends me videos on social media to keep in touch. The other day he sent me something that had a caption with these letters: ROFLMAO.<br/><br/>I'm wondering if this message is in code. I tried a Caesar shift first because, to be honest, that's the only one I really know how to do. I should have listened to my dad when he talked about all that coding he did in the war!<br/><br/>You're supposed to use a number to shift the letters, right?<br/><br/>I used his age, 19, because I thought that would make the most sense.<br/><br/>First, I decoded backwards by 19 and got "YVMSTHV." I thought maybe it was Yves Smith or Yvan Smith, but I don't know anybody by that name.<br/><br/>Then, I decoded forwards. "KHYEFTH." Not much better.<br/><br/>What did I mess up here? Wrong number? Help!</p>
    	</div>

    	<div className="thread-reply-container">
			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@aliceinwonder24</p>
					<p className="reply-content">OP you did everything right. Except that's not a Caesar shift. it's an internet acronym. ROFLMAO stands for "rolling on floor laughing my a** off."</p>
				</div>		

				<div className="thread-reply">
					<div className="inner-thread-reply ">
						<p className="font-bold reply-username">@mandyfromknoxville</p>
						<p className="reply-content">Oh ok! Thanks!</p>
					</div>		
				</div>

			</div>


    	</div>
	</div>

	<div className="blog_entry">
    	<div className="blog_title">
    		<h2>Are some people special?</h2>
    		<h3>@nessietonosferatu</h3>
    	</div>

    	<div className="blog_text">
    		<p>Last weekend, I went with my cousin to help him scope out a venue for his wedding. The place they wanted was a haunted cathedral just outside town, and the wife's family is extremely Religious, and so they wanted us to hire a priest to cleanse the area of any negative energies.<br/><br/>When we got there, the priest was no where to be found. My cousin went to go look for the priest while I waited by the pulpit. He disappeared in a back room for a few minutes, came out confused, and then all of a sudden got this look, pale as a ghost himself. Without saying a word, he just took me by the arm and pulled me out of the cathedral.<br/><br/>As soon as we got in the car, he started yelling at me and asking why I didn't say anything. Apparently, the priest was standing at the organ only maybe ten feet away from me, just staring at nothing, but I didn't see anything!<br/><br/>Later we found out that the priest we were supposed to meet had died the day prior in his home on the property.<br/><br/>Tbh I'm pretty upset that I didn't see him. How is it possible that my cousin could see him and I couldn't, even though I'm the believer of the two of us? What makes him so special?</p>
    	</div>

    	<div className="thread-reply-container">
			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@iamthechupatrooper</p>
					<p className="reply-content">Your cousin is 100% lying</p>
				</div>		
			</div>

			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@everydarndayishalloween</p>
					<p className="reply-content">Maybe you didn't cross the threshold?</p>
				</div>		
			</div>

			<div className="thread-reply">
				<div className="inner-thread-reply ">
					<p className="font-bold reply-username">@idobelieveingene</p>
					<p className="reply-content">You should be thankful! You have no idea if the spirit had good intentions or not.</p>
				</div>		
			</div>


    	</div>
	</div>
    </> 
  );
}